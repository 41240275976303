import {
    TextField,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import React from "react";
import {
    Meal,
    MealTimes,
    DefaultMealTime,
    MealTimeNames,
    MealMap,
} from "../../interfaces";
import MealEditor from "./MealEditor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MealAttributes } from "./MealAttributes";
import Alert from "@mui/material/Alert";

export interface MealFilterProps {
    selectAll: boolean;
    mealNameToMeal: MealMap;
    meals: Meal[];
    allIngredients: string[];
    allAvailableCuisines: string[];
    addMeal: (meal: Meal) => void;
    setFilteredMeals: (filteredMeals: Meal[]) => void;
    initialCollapsed?: boolean;
    allowCreateMeal: boolean;
    mealNotFoundMessage?: string;
}

export const MealFilter = (props: MealFilterProps) => {
    const {
        allIngredients,
        allAvailableCuisines,
        selectAll,
        addMeal,
        meals,
        allowCreateMeal,
        mealNameToMeal,
    } = props;

    const mealNotFoundMessage =
        props.mealNotFoundMessage || "No meals found that matches your search";

    const [collapsed, setCollapsed] = React.useState(!!props.initialCollapsed);

    const [cuisines, setCuisines] = React.useState<string[]>(
        selectAll ? allAvailableCuisines : []
    );
    const [vegetarian, setVegetarian] = React.useState<boolean>(selectAll);
    const [vegan, setVegan] = React.useState<boolean>(selectAll);
    const [nonVegetarian, setNonVegetarian] =
        React.useState<boolean>(selectAll);
    const [mealTimes, setMealTimes] =
        React.useState<MealTimes>(DefaultMealTime);
    const [startCreateMeal, setStartCreateMeal] = React.useState(
        meals.length === 0
    );
    const [createMealKey, setCreateMealKey] = React.useState("");

    const [filter, setFilter] = React.useState<string>("");

    React.useEffect(() => {
        if (!startCreateMeal) {
            setFilter("");
        }
    }, [startCreateMeal]);

    const startCreateNewMeal = React.useCallback(() => {
        setStartCreateMeal(true);
        setCreateMealKey(Math.random().toString());
    }, []);

    const onCreateMeal = React.useCallback(
        (meal: Meal) => {
            startCreateNewMeal();
            addMeal(meal);
        },
        [addMeal, startCreateNewMeal]
    );

    const filteredMeals = React.useMemo(() => {
        return props.meals
            .filter(
                (meal) =>
                    !filter ||
                    meal.name.toLowerCase().includes(filter.toLowerCase()) ||
                    meal.ingredients.some((ingredient) =>
                        ingredient.toLowerCase().includes(filter.toLowerCase())
                    )
            )
            .filter((meal) => !meal.cuisine || cuisines.includes(meal.cuisine))
            .filter(
                (meal) =>
                    collapsed ||
                    (vegetarian && meal.vegetarian) ||
                    (vegan && meal.vegan) ||
                    (nonVegetarian && meal.nonVegetarian) ||
                    (!vegetarian && !vegan && !nonVegetarian)
            )
            .filter((meal) =>
                MealTimeNames.some(
                    (mealTime) =>
                        collapsed ||
                        (mealTimes[mealTime] &&
                            mealTimes[mealTime] === meal.mealTimes[mealTime])
                )
            );
    }, [
        props.meals,
        filter,
        cuisines,
        collapsed,
        vegetarian,
        vegan,
        nonVegetarian,
        mealTimes,
    ]);

    const showCreateNewOption = allowCreateMeal;
    const showNoMealsFound = !allowCreateMeal && filteredMeals.length === 0;

    React.useEffect(() => {
        props.setFilteredMeals(filteredMeals);
    }, [cuisines, mealTimes, props, filter, vegan, vegetarian, filteredMeals]);

    return (
        <>
            {startCreateMeal && (
                <>
                    <Typography variant={"h6"} style={{ marginBottom: "10px" }}>
                        Create a meal to get started
                    </Typography>
                    <MealEditor
                        mealNameToMeal={mealNameToMeal}
                        key={`create-meal-editor-${createMealKey}`}
                        allAvailableCuisines={allAvailableCuisines}
                        allIngredients={allIngredients}
                        Cancel={() => setStartCreateMeal(false)}
                        addOrUpdate={onCreateMeal}
                    />
                </>
            )}

            {!startCreateMeal && (
                <>
                    {showCreateNewOption && (
                        <Button
                            variant="contained"
                            onClick={startCreateNewMeal}>
                            Create Meal
                        </Button>
                    )}
                    <TextField
                        size="small"
                        fullWidth={true}
                        value={filter}
                        label={"Search Meals"}
                        onChange={(e) => {
                            setFilter(e.target.value);
                        }}
                    />
                    <Accordion
                        expanded={!collapsed}
                        onChange={() => setCollapsed(!collapsed)}>
                        <AccordionSummary
                            style={{ marginTop: "5px" }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>
                                {collapsed ? "Show Filter" : "Hide Filter"}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MealAttributes
                                allAvailableCuisines={allAvailableCuisines}
                                cuisines={cuisines}
                                vegetarian={vegetarian}
                                vegan={vegan}
                                nonVegetarian={nonVegetarian}
                                mealTimes={mealTimes}
                                setCuisines={setCuisines}
                                setVegetarian={setVegetarian}
                                setVegan={setVegan}
                                setNonVegetarian={setNonVegetarian}
                                setMealTimes={setMealTimes}
                                allowMultipleCuisines={false}
                                selectAllCuisines={selectAll}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {showNoMealsFound && (
                        <Alert severity="info">{mealNotFoundMessage}</Alert>
                    )}
                </>
            )}
        </>
    );
};
