import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Badge from "@mui/material/Badge";
import { Meal } from "../interfaces";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link as RouterLink } from "react-router-dom";

export interface NavigationBarProps {
    meals: Meal[];
    weeklyMealNames: string[];
    ingredientsInShoppingList: string[];
}
export default function NavigationBar(props: NavigationBarProps) {
    const [value, setValue] = React.useState(0);

    return (
        <BottomNavigation
            style={{ marginTop: "10px", marginBottom: "10px" }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}>
            <BottomNavigationAction
                label={"Weekly Meal Plan"}
                component={RouterLink}
                to="/weekly-meal-plan"
                icon={<CalendarMonthIcon />}
            />
            <BottomNavigationAction
                label="Family Meals"
                component={RouterLink}
                to="/family-meals"
                icon={<MenuBookIcon />}
            />
            <BottomNavigationAction
                label={`Shopping List`}
                component={RouterLink}
                to="/shopping-list"
                icon={
                    <Badge
                        badgeContent={props.ingredientsInShoppingList.length}
                        color="secondary">
                        <ShoppingCartIcon />
                    </Badge>
                }
            />
        </BottomNavigation>
    );
}
