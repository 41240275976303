import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import {
    Checkbox,
    Divider,
    IconButton,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    Typography,
    Stack,
} from "@mui/material";

import React from "react";
import { getItemsInShoppingList, Meal, MealTimeNames } from "../../interfaces";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getLastMadeSince, WeeklyMealOptions } from "../../interfaces";
import WeekDayDrawer from "./WeekdayDrawer";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

export interface MealItemProps {
    meal: Meal;
    ingredientsInShoppingList: string[];
    weeklyMealOptions: WeeklyMealOptions;
    selectedMeals?: string[];
    initialCollapsed?: boolean;
    showMealTime?: boolean;

    onAddItemsToShoppingList: (name: string[]) => void;
    onEditMeal?: (name: string) => void;
    onDeleteMeal?: (name: string) => void;
    onUpdateWeeklyMealOption?: (options: WeeklyMealOptions) => void;
    onToggleSelectMeal?: (name: string) => void;
    onMarkAsMadeToday?: (name: string) => void;
}

export const MealItem = (props: MealItemProps) => {
    const {
        meal,
        ingredientsInShoppingList,
        selectedMeals,
        initialCollapsed,
        onDeleteMeal,
        onEditMeal,
        onToggleSelectMeal,
        onMarkAsMadeToday,
        weeklyMealOptions,
        onUpdateWeeklyMealOption,
        showMealTime,
    } = props;

    const isCollapsible = !!initialCollapsed;
    const [collapsed, setCollapsed] = React.useState(initialCollapsed || false);

    const { name, mealTimes, ingredients, lastMadeDate } = meal;
    const isSelected = selectedMeals && selectedMeals.includes(name);

    const unavailableButInShoppingList = getItemsInShoppingList(
        ingredients,
        ingredientsInShoppingList
    );

    const availableIngredients = ingredients.filter(
        (i) => !unavailableButInShoppingList.includes(i)
    );

    const lastMadeSince = getLastMadeSince(lastMadeDate);

    return (
        <List dense={true}>
            <ListItem
                dense={true}
                disableGutters={true}
                secondaryAction={
                    <>
                        {onToggleSelectMeal && (
                            <Checkbox
                                checked={isSelected}
                                onClick={() => onToggleSelectMeal(name)}
                            />
                        )}
                        {onMarkAsMadeToday && (
                            <Tooltip title="Mark as made today">
                                <IconButton
                                    sx={{ color: "#556B2F" }}
                                    onClick={() => onMarkAsMadeToday(name)}>
                                    <RestaurantIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {onUpdateWeeklyMealOption && (
                            <WeekDayDrawer
                                dayOfWeek={weeklyMealOptions.dayOfWeek}
                                onChange={(dayOfWeek: string) => {
                                    onUpdateWeeklyMealOption({
                                        name: weeklyMealOptions.name,
                                        dayOfWeek,
                                    });
                                }}
                            />
                        )}
                        {onEditMeal && (
                            <Tooltip title="Edit meal">
                                <IconButton onClick={() => onEditMeal(name)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {onDeleteMeal && (
                            <Tooltip title="Delete meal">
                                <IconButton onClick={() => onDeleteMeal(name)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {isCollapsible && (
                            <IconButton
                                onClick={() => setCollapsed(!collapsed)}>
                                {collapsed ? (
                                    <ExpandMoreIcon />
                                ) : (
                                    <ExpandLessIcon />
                                )}
                            </IconButton>
                        )}
                    </>
                }>
                <ListItemText>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography maxWidth={"60%"} variant="body1">
                            {`${name}${
                                showMealTime
                                    ? " (" +
                                          MealTimeNames.filter(
                                              (name) => mealTimes[name]
                                          ).join(", ") +
                                          ")" || ""
                                    : ""
                            }`}
                        </Typography>
                    </Stack>
                </ListItemText>
            </ListItem>
            {!collapsed && (
                <ListItem
                    dense={true}
                    disablePadding={true}
                    disableGutters={true}>
                    <ListItemText>
                        <Typography
                            sx={{ display: "inline" }}
                            component="div"
                            variant="body2">
                            {availableIngredients.join(", ")}
                        </Typography>
                    </ListItemText>
                </ListItem>
            )}
            {!collapsed && (
                <ListItem
                    dense={true}
                    disablePadding={true}
                    disableGutters={true}>
                    <ListItemText>
                        {unavailableButInShoppingList.length > 0 && (
                            <Stack direction="column">
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={"2px"}>
                                    <ErrorOutlineRoundedIcon color="warning" />
                                    <Typography variant="body2" color="red">
                                        <strong>Unavailable:</strong>{" "}
                                        {unavailableButInShoppingList.join(
                                            ", "
                                        )}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )}
                    </ListItemText>
                </ListItem>
            )}
            {!collapsed && (
                <ListItem
                    dense={true}
                    disablePadding={true}
                    disableGutters={true}>
                    <ListItemText>
                        <Typography
                            sx={{ display: "inline" }}
                            component="div"
                            variant="body2">
                            {MealTimeNames.filter(
                                (name) => mealTimes[name]
                            ).join(", ")}
                        </Typography>
                    </ListItemText>
                </ListItem>
            )}
            {!collapsed && lastMadeSince && (
                <ListItem
                    dense={true}
                    disablePadding={true}
                    disableGutters={true}>
                    <ListItemText>
                        <Typography
                            sx={{ display: "inline" }}
                            component="div"
                            variant="body2">
                            {"Last made " + lastMadeSince}
                        </Typography>
                    </ListItemText>
                </ListItem>
            )}

            <Divider />
        </List>
    );
};
