import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    DialogContentText,
    DialogActions,
    Button,
    TextField,
} from "@mui/material";

export interface AddMealDialogProps {
    onMealCreated: (name: string) => void;
    onClose: () => void;
}

export default function FormDialog(props: AddMealDialogProps) {
    const [open, setOpen] = React.useState(true);
    const [name, setName] = React.useState("");
    const { onClose, onMealCreated } = props;

    const onCreateMeal = React.useCallback(() => {
        setOpen(false);
        onMealCreated(name);
        onClose();
    }, [name, onClose, onMealCreated]);

    const onCancelCreateMeal = React.useCallback(() => {
        setOpen(false);
        onClose();
    }, [onClose]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Create Your Own Meal</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter meal name to create a new meal.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Meal Name"
                    required={true}
                    fullWidth
                    variant="standard"
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelCreateMeal}>Cancel</Button>
                <Button disabled={!name} onClick={onCreateMeal}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}
