import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Rating,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    AppProps,
    Meal,
    WeekDayOrder,
    Weekdays,
    getTodaysDayOfWeek,
    WeeklyMealOptions,
    MealSuggestionResults,
    getItemsInShoppingList,
} from "../../interfaces";
import {
    GrayedWeekdayIcons,
    RemoveScheduleIcon,
    WeekdayIcons,
    AddScheduleIcon,
} from "./weekdayIcons/index";
import React from "react";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

interface MealCardProps {
    mealTime: string;
    appProps: AppProps;
    meal: Meal;
    dayOfWeek: string;
    isSuggested?: boolean;
}
function MealCard(props: MealCardProps) {
    const {
        meal,
        mealTime,
        appProps: {
            onUpdateWeeklyMealOption,
            ingredientsInShoppingList,
            addItemsToShoppingList,
        },
        dayOfWeek,
        isSuggested,
    } = props;

    const { ingredients, name } = meal;

    const unavailableButInShoppingList = React.useMemo(
        () => getItemsInShoppingList(ingredients, ingredientsInShoppingList),
        [ingredients, ingredientsInShoppingList]
    );

    const availableIngredients = React.useMemo(
        () =>
            ingredients.filter(
                (i) => !unavailableButInShoppingList.includes(i)
            ),
        [ingredients, unavailableButInShoppingList]
    );

    return (
        <Card style={{ marginBottom: "10px" }} raised={!isSuggested}>
            <CardHeader
                title={`${isSuggested ? "Suggestion: " : ""}${name}`}
                subheader={mealTime}
            />
            <CardContent>
                {unavailableButInShoppingList.length > 0 && (
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Tooltip title="Some ingredients are not available.">
                            <ErrorOutlineRoundedIcon color="warning" />
                        </Tooltip>
                        <Typography variant="body2" color="red">
                            <strong>Unavailable:</strong>{" "}
                            {unavailableButInShoppingList.join(", ")}
                        </Typography>
                    </Stack>
                )}
                <Typography variant="body2" color="text.secondary">
                    {availableIngredients.join(", ")}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {!isSuggested && (
                    <Tooltip title="Remove from menu">
                        <IconButton
                            onClick={() =>
                                onUpdateWeeklyMealOption({
                                    name,
                                    dayOfWeek: "",
                                })
                            }>
                            {RemoveScheduleIcon}
                        </IconButton>
                    </Tooltip>
                )}
                {isSuggested && (
                    <Tooltip title="Add to week">
                        <IconButton
                            onClick={() =>
                                onUpdateWeeklyMealOption({
                                    name,
                                    dayOfWeek,
                                })
                            }>
                            {AddScheduleIcon}
                        </IconButton>
                    </Tooltip>
                )}
                {availableIngredients.length > 0 && (
                    <Tooltip title="Move ingredients to shopping list">
                        <IconButton
                            onClick={() => {
                                addItemsToShoppingList(availableIngredients);
                            }}>
                            <ShoppingCartIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </CardActions>
        </Card>
    );
}

export interface DailyMenuProps {
    appProps: AppProps;
    onSelectDayOfWeek: (dayOfWeek: string) => void;
}

export default function DailyMenu(props: DailyMenuProps) {
    const { appProps, onSelectDayOfWeek } = props;
    const {
        mealNameToMeal,
        getMealOptionsForTheDay,
        getSuggestedMealsForTheDay,
    } = appProps;
    const [dayOfWeek, setDayOfWeek] = React.useState(getTodaysDayOfWeek());

    React.useEffect(() => {
        onSelectDayOfWeek(dayOfWeek);
    }, [dayOfWeek, onSelectDayOfWeek]);

    const mealOptionsForTheDay = React.useMemo(
        () => getMealOptionsForTheDay(dayOfWeek),
        [getMealOptionsForTheDay, dayOfWeek]
    );

    const getMealForMealTime = React.useCallback(
        (weeklyMealOption: WeeklyMealOptions[], mealTimes: string[]) =>
            weeklyMealOption
                .filter((mealOption) =>
                    mealTimes.some(
                        (mealTime) =>
                            mealNameToMeal[mealOption.name] &&
                            mealNameToMeal[mealOption.name].mealTimes[mealTime]
                    )
                )
                .map((mealOption) => mealNameToMeal[mealOption.name]),
        [mealNameToMeal]
    );

    const breakFastMeals = React.useMemo(() => {
        return getMealForMealTime(mealOptionsForTheDay, ["Breakfast"]);
    }, [getMealForMealTime, mealOptionsForTheDay]);

    const lunchAndDinnerMeals = React.useMemo(() => {
        return getMealForMealTime(
            mealOptionsForTheDay.filter(
                (m) => !breakFastMeals.some((b) => b.name === m.name) // Filter breakfast meals so we don't duplicate
            ),
            ["Lunch", "Dinner"]
        );
    }, [breakFastMeals, getMealForMealTime, mealOptionsForTheDay]);

    const [mealSuggestionResult, setMealSuggestionResult] =
        React.useState<MealSuggestionResults>(
            getSuggestedMealsForTheDay(dayOfWeek, [])
        );

    const getMoreSuggestions = React.useCallback(() => {
        const suggestionResult = getSuggestedMealsForTheDay(
            dayOfWeek,
            mealSuggestionResult.suggestions
        );
        setMealSuggestionResult(suggestionResult);
    }, [
        dayOfWeek,
        getSuggestedMealsForTheDay,
        mealSuggestionResult.suggestions,
    ]);

    // Reset meal suggestions when dayOfWeek changes or breakFastMeals changes
    React.useEffect(() => {
        setMealSuggestionResult(getSuggestedMealsForTheDay(dayOfWeek, []));
    }, [
        dayOfWeek,
        getSuggestedMealsForTheDay,
        breakFastMeals,
        lunchAndDinnerMeals,
    ]);

    const suggestedMeals = React.useMemo(() => {
        return getMealForMealTime(mealSuggestionResult.suggestions, [
            "Breakfast",
            "Lunch",
            "Dinner",
        ]);
    }, [getMealForMealTime, mealSuggestionResult]);

    const dayOfWeekName = (value: number | null) => Weekdays[(value || 1) - 1];

    const IconContainer = (props: any) => {
        const { value, ...other } = props;
        const weekDay = dayOfWeekName(value);
        const grayed = weekDay.toLowerCase() !== dayOfWeek.toLowerCase();
        return (
            <span {...other} style={{ marginLeft: "10px" }}>
                {grayed ? GrayedWeekdayIcons[weekDay] : WeekdayIcons[weekDay]}
            </span>
        );
    };

    return (
        <Stack direction="column" spacing={"5px"}>
            <Stack direction="row" spacing={"5px"}>
                <Rating
                    name="highlight-selected-only"
                    defaultValue={WeekDayOrder[dayOfWeek]}
                    IconContainerComponent={IconContainer}
                    onChange={(event, value) => {
                        setDayOfWeek(dayOfWeekName(value));
                    }}
                    max={7}
                    size="large"
                    getLabelText={(value) => dayOfWeekName(value)}
                    highlightSelectedOnly
                />

                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                    {dayOfWeek}
                </Typography>
            </Stack>
            {breakFastMeals.map((meal) => (
                <MealCard
                    key={meal.name}
                    dayOfWeek={dayOfWeek}
                    appProps={appProps}
                    meal={meal}
                    mealTime={"Breakfast"}
                />
            ))}
            {lunchAndDinnerMeals.map((meal) => (
                <MealCard
                    key={meal.name}
                    dayOfWeek={dayOfWeek}
                    appProps={appProps}
                    meal={meal}
                    mealTime="Lunch/Dinner"
                />
            ))}

            {mealSuggestionResult.suggestions.length > 0 && (
                <Alert severity="info" variant="filled">
                    You meal plan for {dayOfWeek} is not complete. Please add
                    following meals to your plan by clicking on green calendar
                    icon.
                </Alert>
            )}
            {suggestedMeals.map((meal) => (
                <MealCard
                    key={meal.name}
                    dayOfWeek={dayOfWeek}
                    appProps={appProps}
                    meal={meal}
                    mealTime={`${["Breakfast", "Lunch", "Dinner"]
                        .filter((t) => meal.mealTimes[t])
                        .join(", ")}`}
                    isSuggested
                />
            ))}
            {breakFastMeals.length === 0 &&
                lunchAndDinnerMeals.length === 0 &&
                mealSuggestionResult.suggestions.length === 0 && (
                    <Alert
                        severity="warning"
                        variant="filled"
                        style={{ marginTop: "20px" }}>
                        There are no more meals to suggest. Please create meals
                        in Family Meals tab and we will help you plan them for{" "}
                        {dayOfWeek}.
                    </Alert>
                )}
            {mealSuggestionResult.suggestions.length > 0 &&
                mealSuggestionResult.hasMore && (
                    <Button
                        variant="outlined"
                        onClick={getMoreSuggestions}
                        startIcon={<TipsAndUpdatesIcon />}>
                        Get more Suggestions
                    </Button>
                )}
        </Stack>
    );
}
