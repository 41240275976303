export const ingredients = Array.from(new Set([
    "Acorn Squash",
    "Alfalfa Sprout",
    "Amaranth",
    "Anise",
    "Artichoke",
    "Arugula",
    "Asparagus",
    "Aubergine",
    "Azuki Bean",
    "Banana Squash",
    "Basil",
    "Bean Sprout",
    "Beet",
    "Black Bean",
    "Black-Eyed Pea",
    "Bok Choy",
    "Borlotti Bean",
    "Broad Beans",
    "Broccoflower",
    "Broccoli",
    "Brussels Sprout",
    "Butternut Squash",
    "Cabbage",
    "Calabrese",
    "Caraway",
    "Carrot",
    "Gazar",
    "Cauliflower",
    "Gobhi",
    "Cayenne Pepper",
    "Celeriac",
    "Celery",
    "Chamomile",
    "Chard",
    "Chayote",
    "Chickpea",
    "Chives",
    "Cilantro",
    "Collard Green",
    "Corn",
    "Corn Salad",
    "Courgette",
    "Cucumber",
    "Daikon",
    "Delicata",
    "Dill",
    "Eggplant",
    "Baingan",
    "Endive",
    "Fennel",
    "Fiddlehead",
    "Frisee",
    "Garlic",
    "Gem Squash",
    "Ginger",
    "Green Bean",
    "Green Pepper",
    "Habanero",
    "Herbs and Spice",
    "Horseradish",
    "Hubbard Squash",
    "Jalapeno",
    "Jerusalem Artichoke",
    "Jicama",
    "Kale",
    "Kidney Bean",
    "Kohlrabi",
    "Lavender",
    "Leek ",
    "Legume",
    "Lemon Grass",
    "Lentils",
    "Lettuce",
    "Lima Bean",
    "Mamey",
    "Mangetout",
    "Marjoram",
    "Mung Bean",
    "Mushroom",
    "Mustard Green",
    "Navy Bean",
    "New Zealand Spinach",
    "Nopale",
    "Okra",
    "Onion",
    "Oregano",
    "Paprika",
    "Parsley",
    "Parsnip",
    "Patty Pan",
    "Pea",
    "Peas",
    "Pinto Bean",
    "Potato (Aloo)",
    "Pumpkin",
    "Radicchio",
    "Radish",
    "Rhubarb",
    "Rosemary",
    "Runner Bean",
    "Rutabaga",
    "Sage",
    "Scallion",
    "Shallot",
    "Skirret",
    "Snap Pea",
    "Soy Bean",
    "Spaghetti Squash",
    "Spinach",
    "Squash",
    "Sweet Potato",
    "Tabasco Pepper",
    "Taro",
    "Tat Soi",
    "Thyme",
    "Topinambur",
    "Tubers",
    "Turnip",
    "Wasabi",
    "Water Chestnut",
    "Watercress",
    "White Radish",
    "Yam",
    "Zucchini",
    "Apple",
    "Apricot",
    "Avocado",
    "Banana",
    "Bell Pepper",
    "Bilberry",
    "Blackberry",
    "Blackcurrant",
    "Blood Orange",
    "Blueberry",
    "Boysenberry",
    "Breadfruit",
    "Canary Melon",
    "Cantaloupe",
    "Cherimoya",
    "Cherry",
    "Chili Pepper",
    "Clementine",
    "Cloudberry",
    "Coconut",
    "Cranberry",
    "Cucumber",
    "Currant",
    "Damson",
    "Date",
    "Dragonfruit",
    "Durian",
    "Eggplant",
    "Elderberry",
    "Feijoa",
    "Fig",
    "Goji Berry",
    "Gooseberry",
    "Grape",
    "Grapefruit",
    "Guava",
    "Honeydew",
    "Huckleberry",
    "Jackfruit",
    "Jambul",
    "Jujube",
    "Kiwi Fruit",
    "Kumquat",
    "Lemon",
    "Lime",
    "Loquat",
    "Lychee",
    "Mandarine",
    "Mango",
    "Mulberry",
    "Nectarine",
    "Nut",
    "Olive",
    "Orange",
    "Papaya",
    "Passionfruit",
    "Peach",
    "Pear",
    "Persimmon",
    "Physalis",
    "Pineapple",
    "Plum",
    "Pomegranate",
    "Pomelo",
    "Purple Mangosteen",
    "Quince",
    "Raisin",
    "Rambutan",
    "Raspberry",
    "Redcurrant",
    "Rock Melon",
    "Salal Berry",
    "Satsuma",
    "Star Fruit",
    "Strawberry",
    "Tamarillo",
    "Tangerine",
    "Tomato",
    "Ugli Fruit",
    "Watermelon",
    "Angelica",
    "Basil",
    "Holy Basil",
    "Thai Basil",
    "Bay Leaf",
    "Indian Bay Leaf",
    "Boldo",
    "Borage",
    "Chervil",
    "Chives",
    "Garlic Chives",
    "Cicely",
    "Coriander Leaf",
    "Cilantro",
    "Bolivian Coriander",
    "Vietnamese Coriander",
    "Culantro",
    "Cress",
    "Curry Leaf",
    "Dill",
    "Epazote",
    "Hemp",
    "Hoja Santa",
    "Houttuynia Cordata",
    "Hyssop",
    "Jimbu",
    "Kinh Gioi",
    "Lavender",
    "Lemon Balm",
    "Lemon Grass",
    "Lemon Myrtle",
    "Lemon Verbena",
    "Limnophila Aromatica",
    "Lovage",
    "Marjoram",
    "Mint",
    "Mugwort",
    "Mitsuba",
    "Oregano",
    "Parsley",
    "Perilla",
    "Rosemary",
    "Rue",
    "Sage",
    "Savory",
    "Sansho",
    "Shiso",
    "Sorrel",
    "Tarragon",
    "Thyme",
    "Woodruff",
    "Aonori",
    "Ajwain",
    "Allspice",
    "Amchoor",
    "Anise",
    "Star Anise",
    "Asafoetida",
    "Camphor",
    "Caraway",
    "Cardamom",
    "Black Cardamom",
    "Cassia",
    "Celery Powder",
    "Celery Seed",
    "Charoli",
    "Chenpi",
    "Cinnamon",
    "Clove",
    "Coriander Seed",
    "Cubeb",
    "Cumin",
    "Nigella Sativa",
    "Bunium Persicum",
    "Dill",
    "Dill Seed",
    "Fennel",
    "Fenugreek",
    "Fingerroot",
    "Greater Galangal",
    "Lesser Galangal",
    "Garlic",
    "Ginger",
    "Aromatic Ginger",
    "Golpar",
    "Grains of Paradise",
    "Grains of Selim",
    "Horseradish",
    "Juniper Berry",
    "Kokum",
    "Korarima",
    "Dried Lime",
    "Liquorice",
    "Litsea Cubeba",
    "Mace",
    "Mango-Ginger",
    "Mastic",
    "Mahlab",
    "Black Mustard",
    "Brown Mustard",
    "White Mustard",
    "Nigella",
    "Njangsa",
    "Nutmeg",
    "Alligator Pepper",
    "Brazilian Pepper",
    "Chili Pepper",
    "Cayenne Pepper",
    "Paprika",
    "Long Pepper",
    "Peruvian Pepper",
    "East Asian Pepper",
    "Sichuan Pepper",
    "Sansho",
    "Tasmanian Pepper",
    "Black Peppercorn",
    "Green Peppercorn",
    "White Peppercorn",
    "Pomegranate Seed",
    "Poppy Seed",
    "Radhuni",
    "Rose",
    "Saffron",
    "Salt",
    "Sarsaparilla",
    "Sassafras",
    "Sesame",
    "Shiso",
    "Sumac",
    "Tamarind",
    "Tonka Bean",
    "Turmeric",
    "Uzazi",
    "Vanilla",
    "Wasabi",
    "Yuzu",
    "Zedoary",
    "Zereshk",
    "Zest",
    "Adjika",
    "Advieh",
    "Baharat",
    "Beau Monde Seasoning",
    "Berbere",
    "Bouquet Garni",
    "Buknu",
    "Chaat Masala",
    "Chaunk",
    "Chili Powder",
    "Crab Boil",
    "Curry Powder",
    "Doubanjiang",
    "Douchi",
    "Duqqa",
    "Fines Herbes",
    "Five-Spice Powder",
    "Garam Masala",
    "Garlic Powder",
    "Garlic Salt",
    "Gochujang",
    "Harissa",
    "Hawaij",
    "Herbes De Provence",
    "Idli Podi",
    "Jamaican Jerk Spice",
    "Khmeli Suneli",
    "Lemon Pepper",
    "Mitmita",
    "Mixed Spice",
    "Montreal Steak Seasoning",
    "Mulling Spices",
    "Old Bay Seasoning",
    "Onion Powder",
    "Panch Phoron",
    "Persillade",
    "Powder-Douce",
    "Pumpkin Pie Spice",
    "Qâlat Daqqa",
    "Quatre Épices",
    "Ras El Hanout",
    "Recado Rojo",
    "Sharena Sol",
    "Shichimi",
    "Tabil",
    "Tandoori Masala",
    "Vadouvan",
    "Yuzukosho",
    "Za'atar",
    "Milk",
    "Yougurt",
    "Eggs",
    "Almonds",
    "Cashews",
    "Pecans",
    "Walnuts",
    "Pistachios",
    "Macadamia",
    "Pine Nuts",
    "Raisins",
    "Almond Flour",
    "Corn Flour",
    "Coconut Flour",
    "Oat Flour",
    "Rice Flour",
    "Soy Flour",
    "Wheat Flour",
    "Green Chili",
    "Red Chili",
    "Paneer",
    "Guava",
    "Coconut",
    "Coconut Milk",
    "Coconut Oil",
    "Sooji",
    "Semolina",
    "Sesame Oil",
    "Sesame Seeds",
    "Curry Leaves",
    "Coconut Water",
    "Fish Sauce",
    "Fish",
    "Chicken",
    "Beef",
    "Pork",
    "Lamb",
    "Mutton",
    "Goat",
    "Rabbit",
    "Goat Milk",
    "Cheese",
    "Cottage Cheese",
    "Butter",
    "Cream",
    "Ghee",
    "Guacamole",
    "Honey",
    "Salsa",
    "Raddish",
    "Bhindi",
    "Chana",
    "Kala Chana",
    "Garbanzo Beans",
    "Chole",
    "Methi Seeds",
    "Mushroom",
    "Methi leaves",
    "Fenugreek Leaves",
]));
