import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    TextField,
    Stack,
} from "@mui/material";
import React from "react";
import { MealTimes } from "../../interfaces";
import { CuisinesCheckboxGroup } from "./CuisinesCheckboxGroup";
import { MealTimeCheckboxGroup } from "./MealTimeCheckboxGroup";

export interface MealAttributesProps {
    allAvailableCuisines: string[];
    cuisines: string[];
    vegetarian: boolean;
    vegan: boolean;
    nonVegetarian: boolean;
    mealTimes: MealTimes;
    setCuisines: (cuisines: string[]) => void;
    setVegetarian: (vegetarian: boolean) => void;
    setVegan: (vegan: boolean) => void;
    setNonVegetarian: (nonVegetarian: boolean) => void;
    setMealTimes: (mealTimes: MealTimes) => void;

    allowMultipleCuisines: boolean;
    selectAllCuisines: boolean;
}

export function MealAttributes(props: MealAttributesProps) {
    const {
        allAvailableCuisines,
        cuisines,
        vegetarian,
        vegan,
        nonVegetarian,
        mealTimes,
        allowMultipleCuisines,
        selectAllCuisines,
        setCuisines,
        setVegetarian,
        setVegan,
        setNonVegetarian,
        setMealTimes,
    } = props;
    return (
        <>
            <MealTimeCheckboxGroup
                mealTimes={mealTimes}
                onChange={setMealTimes}
            />

            <Stack direction="row">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={vegetarian}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setVegetarian(e.target.checked);
                            }}
                            name="Vegetarian"
                        />
                    }
                    label="Vegetarian"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={vegan}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setVegan(e.target.checked);
                            }}
                            name="Vegan"
                        />
                    }
                    label="Vegan"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={nonVegetarian}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setNonVegetarian(e.target.checked);
                            }}
                            name="Non-vegetarian"
                        />
                    }
                    label="Non-vegetarian"
                />
            </Stack>
            {!allowMultipleCuisines ? (
                <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={allAvailableCuisines}
                    value={cuisines[0]}
                    onChange={(e, value) => {
                        setCuisines([value || ""]);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            size="small"
                            label="Cuisine"
                            onChange={(e) => setCuisines([e.target.value])}
                        />
                    )}
                />
            ) : (
                allAvailableCuisines.length > 0 && (
                    <CuisinesCheckboxGroup
                        initialSelection={
                            selectAllCuisines ? allAvailableCuisines : cuisines
                        }
                        availableCuisines={allAvailableCuisines}
                        onChange={setCuisines}
                    />
                )
            )}
        </>
    );
}
