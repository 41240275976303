import { SvgIcon } from "@mui/material";
import {
    getPrimaryIconColor,
    getSecondaryIconColor,
} from "../../../interfaces";

export default function Sunday(props: any) {
    const primaryColor = getPrimaryIconColor(props.grayed);
    const secondaryColor = getSecondaryIconColor(props.grayed);

    return (
        <SvgIcon {...props}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 116.61">
                <defs>
                    <style>{`.cls-sunday-1{fill:gray;}.cls-sunday-2{fill:#fff;}.cls-sunday-2,.cls-sunday-3,.cls-sunday-4{fill-rule:evenodd;}.cls-sunday-3{fill:${primaryColor}}.cls-sunday-4{fill:${secondaryColor};}.cls-sunday-5{fill:#1a1a1a;}`}</style>
                </defs>
                <title>week-day-sunday</title>
                <path
                    className="cls-sunday-1"
                    d="M111.36,116.61H11.52A11.57,11.57,0,0,1,0,105.09V40H122.88v65.07a11.56,11.56,0,0,1-11.52,11.52Z"
                />
                <path
                    className="cls-sunday-2"
                    d="M12.75,112.92h97.38a9.1,9.1,0,0,0,9.06-9.06V44.94H3.69v58.92a9.08,9.08,0,0,0,9.06,9.06Z"
                />
                <path
                    className="cls-sunday-3"
                    d="M11.52,6.67h99.84a11.57,11.57,0,0,1,11.52,11.52V44.94H0V18.19A11.56,11.56,0,0,1,11.52,6.67Zm24.79,9.75A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Zm49.79,0a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-sunday-4"
                    d="M86.1,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.06,11.06,0,0,1,7.74-3.16Zm0,1.79a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-sunday-4"
                    d="M36.31,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.08,11.08,0,0,1,7.74-3.16Zm0,1.79A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-sunday-5"
                    d="M80.54,4.56C80.54,2,83,0,86.1,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-sunday-5"
                    d="M30.75,4.56C30.75,2,33.24,0,36.31,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-sunday-5"
                    d="M22.1,92.15l1.23-7.24a29.88,29.88,0,0,0,7.26,1,41.93,41.93,0,0,0,5.19-.26V83.47l-3.94-.35c-3.57-.33-6-1.18-7.35-2.57s-2-3.44-2-6.16q0-5.61,2.43-7.72t8.27-2.1a46.39,46.39,0,0,1,10.52,1.09l-1.09,7A43.08,43.08,0,0,0,36.09,72a32.72,32.72,0,0,0-4.16.22v2.15l3.15.31q5.74.57,7.94,2.74a8.12,8.12,0,0,1,2.19,6,12.82,12.82,0,0,1-.74,4.67A8,8,0,0,1,42.69,91a6.61,6.61,0,0,1-2.91,1.51,16.71,16.71,0,0,1-3.31.64c-.95.07-2.22.11-3.8.11A46.35,46.35,0,0,1,22.1,92.15ZM57.27,65.22V85.79h3.12a4.47,4.47,0,0,0,2.28-.41c.41-.28.61-.92.61-1.91V65.22h8.77v15.4a30.2,30.2,0,0,1-.48,6,8.69,8.69,0,0,1-1.8,3.86,6.81,6.81,0,0,1-3.59,2.2,23.43,23.43,0,0,1-5.92.61,23.1,23.1,0,0,1-5.9-.61,6.82,6.82,0,0,1-3.58-2.2A8.78,8.78,0,0,1,49,86.62a29.36,29.36,0,0,1-.49-6V65.22ZM92.53,92.63,85.82,82.9a4.59,4.59,0,0,1-.44-2.11h-.17V92.63H76.44V65.22h8.24L91.39,75a4.41,4.41,0,0,1,.44,2.1H92V65.22h8.77V92.63Z"
                />
            </svg>
        </SvgIcon>
    );
}
