import * as React from "react";

import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { WeekdayIcons } from "./weekdayIcons";

export interface WeekDayDrawerProps {
    dayOfWeek: string;
    onChange: (dayOfWeek: string) => void;
}

const getActions = (
    selection: string,
    onClick: (selection: string) => void
) => {
    const clickHandler = (selection: string) => {
        return () => onClick(selection);
    };

    const label = (name: string) =>
        selection === name ? `${name} (selected)` : name;
    const getListItem = (name: string) => (
        <ListItem button key={name} onClick={clickHandler(name)}>
            <ListItemIcon>{WeekdayIcons[name]}</ListItemIcon>
            <ListItemText primary={label(name)} />
        </ListItem>
    );
    const actions = [
        getListItem("Monday"),
        getListItem("Tuesday"),
        getListItem("Wednesday"),
        getListItem("Thursday"),
        getListItem("Friday"),
        getListItem("Saturday"),
        getListItem("Sunday"),
    ];

    if (selection) {
        actions.push(
            <ListItem button key={"Remove"} onClick={clickHandler("")}>
                <ListItemIcon>{WeekdayIcons["Remove"]}</ListItemIcon>
                <ListItemText primary={"Remove"} />
            </ListItem>
        );
    }
    return actions;
};

export default function WeekDayDrawer(props: WeekDayDrawerProps) {
    const { onChange } = props;
    const [open, setOpen] = React.useState(false);

    const setOpenHandler = React.useCallback((open: boolean) => {
        return () => {
            setOpen(open);
        };
    }, []);

    const [dayOfWeek, setDayOfWeek] = React.useState(props.dayOfWeek);
    const handleAction = React.useCallback(
        (action: string) => {
            setDayOfWeek(action);
            onChange(action);
            setOpen(false);
        },
        [onChange]
    );

    return (
        <React.Fragment key={"bottom"}>
            <IconButton onClick={setOpenHandler(true)}>
                {WeekdayIcons[dayOfWeek] || WeekdayIcons[""]}
            </IconButton>
            <Drawer
                anchor={"bottom"}
                open={open}
                onClose={setOpenHandler(false)}>
                <List>{getActions(dayOfWeek, handleAction)}</List>
            </Drawer>
        </React.Fragment>
    );
}
