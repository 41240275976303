import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Button,
    Stack,
    TextField,
    IconButton,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    Autocomplete,
} from "@mui/material";
import {
    DefaultMealTime,
    Meal,
    useGlobal,
    setGlobal,
    MealMap,
} from "../../interfaces";
import { MealAttributes } from "./MealAttributes";
import { Help } from "../Help";
import { GlobalMealNames, GlobalMeals } from "../../globalMeals";
import { AutoCompleteWithAddNew } from "./AutoCompleteWithAddNew";

function getListItem(
    ingredient: string,
    removeIngredient: (i: string) => void
) {
    return (
        <ListItem
            key={ingredient}
            secondaryAction={
                <Tooltip title="Delete">
                    <IconButton onClick={() => removeIngredient(ingredient)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            }>
            <ListItemText primary={ingredient} />
        </ListItem>
    );
}

export interface MealEditorProps {
    allIngredients: string[];
    allAvailableCuisines: string[];
    mealNameToMeal: MealMap;
    addOrUpdate: (meal: Meal) => void;
    existingMeal?: Meal;
    Cancel?: () => void;
}

const help = () => (
    <Help
        title="HOW IT WORKS"
        messages={[
            "To create your family meal:",
            "1) Enter meal name.",
            "2) Choose if the meal is Breakfast, Lunch or Dinner.",
            "3) Pick Cuisine.",
            "5) Add main ingredients for the meal.",
            "6) Save meal.",
        ]}></Help>
);

export default function MealEditor(props: MealEditorProps) {
    const {
        allIngredients,
        existingMeal,
        allAvailableCuisines,
        addOrUpdate,
        Cancel,
        mealNameToMeal,
    } = props;
    const {
        name: originalMealName,
        ingredients: originalIngredients,
        mealTimes: originalMealTimes,
        cuisine: originalCuisine,
        vegan: originalVegan,
        vegetarian: originalVegetarian,
        nonVegetarian: originalNonVegetarian,
    } = existingMeal || {
        name: "",
        ingredients: [],
        mealTimes: DefaultMealTime,
        cuisine: "",
        vegan: false,
        vegetarian: false,
        nonVegetarian: false,
    };

    const lastFilterValue = useGlobal("create-meal", {
        vegetarian: originalVegetarian,
        vegan: originalVegan,
        nonVegetarian: originalNonVegetarian,
        cuisine: originalCuisine,
        mealTimes: originalMealTimes,
    });

    const [isCreateNew] = React.useState(!existingMeal);
    const [mealName, setMealName] = React.useState(originalMealName);
    const [ingredients, setIngredients] = React.useState(originalIngredients);
    const [ingredientNames, setIngredientNames] = React.useState<string[]>([]);
    const [tempIngredientName, setTempIngredientName] = React.useState("");
    const [mealTimes, setMealTimes] = React.useState(
        existingMeal ? originalMealTimes : lastFilterValue.mealTimes
    );
    const [cuisines, setCuisines] = React.useState(
        existingMeal ? [originalCuisine] : [lastFilterValue.cuisine]
    );
    const [vegetarian, setVegetarian] = React.useState<boolean>(
        existingMeal ? !!originalVegetarian : lastFilterValue.vegetarian
    );
    const [vegan, setVegan] = React.useState<boolean>(
        existingMeal ? !!originalVegan : lastFilterValue.vegan
    );
    const [nonVegetarian, setNonVegetarian] = React.useState<boolean>(
        existingMeal ? !!originalNonVegetarian : lastFilterValue.nonVegetarian
    );

    const addIngredients = React.useCallback(() => {
        if (
            (ingredientNames && ingredientNames.length > 0) ||
            tempIngredientName
        ) {
            const ingredientParts = Array.from(
                new Set(
                    ingredientNames
                        .concat(...tempIngredientName.split(","))
                        .map((s) => s.trim())
                        .filter((s) => s && !ingredients.includes(s))
                )
            );
            setIngredients([...ingredients, ...ingredientParts]);
            setTempIngredientName("");
            setIngredientNames([]);
        }
    }, [ingredientNames, ingredients, tempIngredientName]);

    const removeIngredient = React.useCallback(
        (ingredient: string) => {
            setIngredients(ingredients.filter((i) => i !== ingredient));
        },
        [ingredients]
    );

    const mealOptions = React.useMemo(
        () => GlobalMealNames.filter((m) => !mealNameToMeal[m]),
        [mealNameToMeal]
    );
    return (
        <>
            {help()}
            {isCreateNew ? (
                <AutoCompleteWithAddNew
                    options={mealOptions}
                    initialValue={mealName}
                    label={"Enter meal name"}
                    onChange={(name) => {
                        if (name) {
                            setMealName(name);
                            if (GlobalMeals[name]) {
                                setIngredients(GlobalMeals[name].ingredients);
                                setCuisines([GlobalMeals[name].cuisine]);
                                setVegetarian(GlobalMeals[name].vegetarian);
                                setVegan(GlobalMeals[name].vegan);
                                setNonVegetarian(
                                    GlobalMeals[name].nonVegetarian
                                );
                                setMealTimes(GlobalMeals[name].mealTimes);
                            }
                        }
                    }}
                />
            ) : (
                <TextField
                    size="small"
                    fullWidth={true}
                    value={mealName}
                    label={"Meal Name"}
                    style={{ marginTop: "1rem" }}
                    onChange={(e) => {
                        setMealName(e.target.value);
                    }}
                />
            )}
            <MealAttributes
                allAvailableCuisines={allAvailableCuisines}
                cuisines={cuisines}
                vegetarian={vegetarian}
                vegan={vegan}
                nonVegetarian={nonVegetarian}
                mealTimes={mealTimes}
                setCuisines={setCuisines}
                setVegetarian={setVegetarian}
                setVegan={setVegan}
                setNonVegetarian={setNonVegetarian}
                setMealTimes={setMealTimes}
                allowMultipleCuisines={false}
                selectAllCuisines={false}
            />
            <Stack direction="row" spacing={"10px"}>
                <Autocomplete
                    id="free-solo-demo"
                    fullWidth={true}
                    freeSolo
                    multiple={true}
                    options={allIngredients}
                    value={ingredientNames}
                    onChange={(e, value) => value && setIngredientNames(value)}
                    inputValue={tempIngredientName}
                    onInputChange={(e, value) => setTempIngredientName(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            label="Ingredient name(s) (Comma separated)"
                        />
                    )}
                />
                <Button
                    disabled={
                        (!ingredientNames || ingredientNames.length === 0) &&
                        !tempIngredientName
                    }
                    variant="contained"
                    onClick={addIngredients}>
                    Add
                </Button>
            </Stack>
            <List dense={true}>
                {ingredients.map((ingredient: string) =>
                    getListItem(ingredient, removeIngredient)
                )}
            </List>
            <Stack direction="row" spacing={"10px"}>
                <Button
                    disabled={!mealName || ingredients.length === 0}
                    variant="contained"
                    onClick={() => {
                        if (!existingMeal) {
                            setGlobal("create-meal", {
                                vegetarian,
                                vegan,
                                nonVegetarian,
                                cuisine: cuisines[0],
                                mealTimes,
                            });
                        }
                        addOrUpdate({
                            ...existingMeal,
                            name: mealName,
                            mealTimes,
                            ingredients,
                            cuisine: cuisines[0],
                            vegan,
                            nonVegetarian,
                            vegetarian,
                        });
                    }}>
                    Save
                </Button>
                {Cancel && (
                    <Button variant="contained" onClick={Cancel}>
                        Cancel
                    </Button>
                )}
            </Stack>
        </>
    );
}
