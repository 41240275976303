import "./App.css";
import * as React from "react";
import { Alert, Container, Drawer } from "@mui/material";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import {
    Meal,
    AppProps,
    removeSpecialCharsAndSetTitleCase,
    getLastMadeSince,
    MealMap,
    StringMap,
    getTodaysDayOfWeek,
    getTimeZone,
    getTimeZoneOffset,
} from "./interfaces";
import MainScreen from "./components/MainScreen";
import { getCollectionRefs } from "./db";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
    deleteDoc,
    setDoc,
    updateDoc,
    deleteField,
    DocumentReference,
} from "firebase/firestore";
import {
    UserPreferences,
    WeeklyMealOptions,
    WeeklyMealMap,
} from "./interfaces";
import { ingredients } from "./ingredients";
import Snackbar from "@mui/material/Snackbar";
import firebase from "firebase/compat/app";
import { WeekDayOrder, getMealComparer } from "./interfaces";
import {
    getAnalytics,
    logEvent as gaLogEvent,
    setUserId as gaSetUserId,
} from "@firebase/analytics";
import {
    ConfirmationBar,
    ConfirmationBarProps,
} from "./components/sub-components/ConfirmationBar";
import { addDays } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import { deleteToken, getToken } from "./notifications";

function keys(obj?: { [key: string]: any }) {
    return obj ? Object.keys(obj) : [];
}

export function Main() {
    const [user, setUser] = React.useState<firebase.User | null>(null);

    // Listen to the Firebase Auth state and set the local state.
    React.useEffect(() => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const unregisterAuthObserver = firebase
            .auth()
            .onAuthStateChanged((user: firebase.User | null) => {
                if (user) {
                    console.log("User Id", user.uid);
                }
                setUser(user);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    const collectionRefs = React.useMemo(
        () => (user ? getCollectionRefs() : null),
        [user]
    );
    const [confirmationProps, setConfirmationProps] =
        React.useState<ConfirmationBarProps>();

    const [mealsMapDoc, mealsMapDocLoading, mealsMapDocError] =
        useDocumentData<MealMap>(collectionRefs?.mealsMap);

    const logEvent = React.useMemo(() => {
        const analytics = getAnalytics(firebase.app());
        if (user) {
            gaSetUserId(analytics, user.uid);
        }
        return (eventName: string, params?: { [key: string]: any }) => {
            eventName = eventName.toLowerCase().replace(" ", "_");
            gaLogEvent(analytics, eventName, params);
        };
    }, [user]);

    const mealNameToMeal: MealMap = React.useMemo(() => {
        if (!mealsMapDoc) {
            return {};
        }
        const keys = Object.keys(mealsMapDoc);
        const mealMap: MealMap = {};
        keys.forEach((key) => {
            const meal = mealsMapDoc[key];
            if (meal) {
                mealMap[key] = meal;
            }
        });

        return mealMap;
    }, [mealsMapDoc]);

    const [userPreferencesDoc, userPreferencesLoading, userPreferencesError] =
        useDocumentData<UserPreferences>(collectionRefs?.userPreference);

    const [
        ingredientsInShoppingListDoc,
        ingredientsInShoppingListLoading,
        ingredientsInShoppingListError,
    ] = useDocumentData<StringMap>(collectionRefs?.ingredientsInShoppingList);

    const [weeklyMealMapDoc, weeklyMealsLoading, weeklyMealsError] =
        useDocumentData<WeeklyMealMap>(collectionRefs?.weeklyMeals);

    const loading =
        mealsMapDocLoading ||
        ingredientsInShoppingListLoading ||
        userPreferencesLoading ||
        weeklyMealsLoading;

    const error =
        mealsMapDocError ||
        ingredientsInShoppingListError ||
        userPreferencesError ||
        weeklyMealsError;

    if (error) {
        debugger;
        console.log("Error", error);
    }

    const userPreferences = React.useMemo<UserPreferences>(() => {
        return (
            userPreferencesDoc || {
                showIntro: true,
                sendNotifications: false,
                notificationToken: "",
                timeZone: getTimeZone(),
                timeZoneOffset: getTimeZoneOffset(),
                ref: undefined,
            }
        );
    }, [userPreferencesDoc]);

    const weeklyMealNames = React.useMemo(
        () => keys(weeklyMealMapDoc),
        [weeklyMealMapDoc]
    );

    const ingredientsInShoppingList = React.useMemo(
        () => keys(ingredientsInShoppingListDoc).sort(),
        [ingredientsInShoppingListDoc]
    );

    const meals = React.useMemo<Meal[]>(() => {
        const keys = Object.keys(mealNameToMeal);
        const meals: Meal[] = [];
        keys.forEach((key) => {
            const meal = mealNameToMeal[key];
            if (meal) {
                meals.push(meal);
            }
        });
        logEvent("meals", { mealCount: meals.length + "" });
        return meals;
    }, [logEvent, mealNameToMeal]);

    const weeklyMeals = React.useMemo(
        () => weeklyMealNames.map((mealName) => mealNameToMeal[mealName]),
        [mealNameToMeal, weeklyMealNames]
    );

    const mealNameToLastMadeSince = React.useMemo(
        () =>
            meals.reduce((acc: { [mealName: string]: string }, meal) => {
                const lastMadeSince = getLastMadeSince(meal.lastMadeDate);
                acc[meal.name] = lastMadeSince ? `(${lastMadeSince})` : "";
                return acc;
            }, {}),
        [meals]
    );

    const allIngredients = React.useMemo(
        () =>
            Array.from(new Set([...ingredientsInShoppingList, ...ingredients])),
        [ingredientsInShoppingList]
    );

    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (!user) {
            navigate("/login");
        } else if (!loading) {
            if (userPreferences && userPreferences.showIntro) {
                navigate("/setup");
            } else if (location.pathname === "/setup") {
                navigate("/");
            }
        }
    }, [navigate, loading, userPreferences, user, location.pathname]);

    const useCachedCallBack = (
        name: string,
        names: string[],
        docRef?: DocumentReference<StringMap>
    ) => {
        return React.useCallback(
            (items: string[]) => {
                items = items.filter((item) => !names.includes(item));
                const map = items.reduce((acc: StringMap, item) => {
                    acc[item] = item;
                    return acc;
                }, {});

                if (docRef) {
                    if (names.length > 0) {
                        logEvent(`Updated ${name}`);
                        updateDoc(docRef, map);
                    } else {
                        logEvent(`Created ${name}`);
                        setDoc(docRef, map);
                    }
                }
            },
            [docRef, name, names]
        );
    };

    const updateMeal = React.useCallback(
        (originalName: string, meal: Meal) => {
            async function update() {
                if (
                    collectionRefs &&
                    mealsMapDoc &&
                    mealsMapDoc[originalName]
                ) {
                    meal.name = removeSpecialCharsAndSetTitleCase(meal.name);
                    meal.ingredients = meal.ingredients.map((i) =>
                        removeSpecialCharsAndSetTitleCase(i)
                    );
                    try {
                        const batch = collectionRefs.getBatch();
                        const doc: any = {};
                        doc[meal.name] = meal;
                        batch.update(
                            collectionRefs.mealsMap,
                            collectionRefs.mealsMap.converter?.toFirestore(doc)
                        );
                        if (originalName !== meal.name) {
                            const doc2: any = {};
                            doc2[originalName] = deleteField();

                            //Updating weekly meals
                            if (
                                weeklyMealMapDoc &&
                                weeklyMealMapDoc[originalName]
                            ) {
                                const doc3: any = {};
                                doc3[originalName] = deleteField();
                                doc3[meal.name] = {
                                    name: meal.name,
                                    dayOfWeek:
                                        weeklyMealMapDoc[originalName]
                                            .dayOfWeek,
                                };
                                batch.update(collectionRefs.weeklyMeals, doc3);
                            }
                            batch.update(collectionRefs.mealsMap, doc2);
                        }
                        await batch.commit();
                        logEvent("Updated meal.");
                    } catch (e) {
                        setNotification(
                            `${meal.name} update failed with error ${e}`
                        );
                    }
                }
            }
            update();
        },
        [collectionRefs, logEvent, mealsMapDoc, weeklyMealMapDoc]
    );

    const markAsMadeToday = React.useCallback(
        (name: string, date?: Date) => {
            const meal = meals.find((meal) => meal.name === name);
            if (meal) {
                meal.lastMadeDate = date || new Date();
                updateMeal(meal.name, meal);
                logEvent("Mark as made today.");
            }
        },
        [logEvent, meals, updateMeal]
    );

    const addItemsToShoppingList = useCachedCallBack(
        "ingredientsInShoppingList",
        ingredientsInShoppingList,
        collectionRefs?.ingredientsInShoppingList
    );

    const populateShoppingList = React.useCallback(() => {
        if (weeklyMealNames) {
            const ingredients = new Set<string>();
            weeklyMealNames.forEach((item) => {
                const mealIngredients = mealsMapDoc
                    ? mealsMapDoc[item]?.ingredients || []
                    : [];
                mealIngredients.forEach((i) => ingredients.add(i));
            });
            let items = Array.from(ingredients);
            if (ingredientsInShoppingList) {
                const shoppingSet = new Set(ingredientsInShoppingList);
                items = items.filter((i) => shoppingSet.has(i) === false);
            }
            addItemsToShoppingList(items);
            if (items.length > 0) {
                setNotification(
                    `Added ${items.length} items to shopping list based on your weekly meals.`
                );
            }
            logEvent("Populate Shopping List.");
        }
    }, [
        addItemsToShoppingList,
        ingredientsInShoppingList,
        logEvent,
        mealsMapDoc,
        weeklyMealNames,
    ]);

    const onUpdateWeeklyMealOption = React.useCallback(
        (item: WeeklyMealOptions) => {
            item.name = removeSpecialCharsAndSetTitleCase(item.name);
            if (collectionRefs && collectionRefs.weeklyMeals) {
                const map: any = {};
                if (item.dayOfWeek === "") {
                    map[item.name] = deleteField();
                } else {
                    map[item.name] = item;
                }

                if (!weeklyMealMapDoc || keys(weeklyMealMapDoc).length === 0) {
                    logEvent("Created first meal.");
                    setDoc(
                        collectionRefs.weeklyMeals,
                        collectionRefs.weeklyMeals.converter?.toFirestore(map)
                    );
                } else {
                    logEvent("Added or updated meals.");
                    updateDoc(collectionRefs.weeklyMeals, map);
                }

                // Update last made date
                if (item.dayOfWeek) {
                    const todayDayOfWeek = WeekDayOrder[getTodaysDayOfWeek()];
                    const suggestedDayOfWeek = WeekDayOrder[item.dayOfWeek];

                    const diff = Math.abs(todayDayOfWeek - suggestedDayOfWeek);

                    const lastMadeDate = addDays(new Date(), diff);
                    markAsMadeToday(item.name, lastMadeDate);
                }
                populateShoppingList();
            }
        },
        [
            collectionRefs,
            logEvent,
            markAsMadeToday,
            populateShoppingList,
            weeklyMealMapDoc,
        ]
    );

    const addMeal = React.useCallback(
        (meal: Meal) => {
            if (collectionRefs && !mealNameToMeal[meal.name]) {
                meal.name = removeSpecialCharsAndSetTitleCase(meal.name);
                meal.ingredients = meal.ingredients.map((i) =>
                    removeSpecialCharsAndSetTitleCase(i)
                );

                const mealName = meal.name;
                const doc: any = {};
                doc[mealName] = meal;
                if (meals.length === 0) {
                    logEvent("Created first meal.");
                    setDoc(collectionRefs.mealsMap, doc);
                } else {
                    logEvent("Created meal.");
                    updateDoc(
                        collectionRefs.mealsMap,
                        collectionRefs.mealsMap.converter?.toFirestore(doc)
                    );
                }

                // // Add ingredients to shopping list that are not in Shopping list
                // const ingredientsToAdd = meal.ingredients.filter(
                //     (i) => allIngredients.find((a) => a === i) === undefined
                // );
                // if (ingredientsToAdd.length > 0) {
                //     addItemsToShoppingList(ingredientsToAdd.map((i) => i));
                // }
            }
        },
        [collectionRefs, mealNameToMeal, meals.length, logEvent]
    );

    const deleteMeal = React.useCallback(
        (originalName: string, autoConfirm?: boolean) => {
            const deleteMealInternal = () => {
                if (
                    collectionRefs &&
                    mealsMapDoc &&
                    mealsMapDoc[originalName]
                ) {
                    const doc: any = {};
                    doc[originalName] = deleteField();
                    logEvent("Deleted meal.");
                    updateDoc(collectionRefs.mealsMap, doc);

                    // Remove meal from weekly meals
                    onUpdateWeeklyMealOption({
                        name: originalName,
                        dayOfWeek: "",
                    });
                }
            };
            if (autoConfirm) {
                deleteMealInternal();
            } else {
                setConfirmationProps({
                    open: true,
                    message: `Are you sure you want to permanently delete ${originalName}?`,
                    onClose: () => setConfirmationProps(undefined),
                    actionLabel: "Delete",
                    onAction: () => {
                        setConfirmationProps(undefined);

                        deleteMealInternal();
                    },
                });
            }
        },
        [collectionRefs, logEvent, mealsMapDoc, onUpdateWeeklyMealOption]
    );

    const setShowIntro = React.useCallback(
        (showIntro: boolean) => {
            if (collectionRefs) {
                if (userPreferences && userPreferences.ref) {
                    updateDoc(userPreferences.ref, { showIntro });
                } else {
                    setDoc(collectionRefs.userPreference, {
                        showIntro,
                        sendNotifications: false,
                        notificationToken: "",
                        timeZone: getTimeZone(),
                        timeZoneOffset: getTimeZoneOffset(),
                    });
                }
            }
        },
        [collectionRefs, userPreferences]
    );

    const updateSendNotifications = React.useCallback(
        (sendNotifications: boolean) => {
            if (collectionRefs) {
                if (userPreferences && userPreferences.ref) {
                    updateDoc(userPreferences.ref, {
                        sendNotifications,
                    });
                } else {
                    setDoc(collectionRefs.userPreference, {
                        showIntro: true,
                        sendNotifications,
                        notificationToken: "",
                        timeZone: getTimeZone(),
                        timeZoneOffset: getTimeZoneOffset(),
                    });
                }
            }

            async function setupNotifications() {
                const notificationToken = await getToken(setNotification);
                if (
                    notificationToken &&
                    userPreferences &&
                    userPreferences.ref
                ) {
                    console.log("Notification token: ", notificationToken);
                    updateDoc(userPreferences.ref, {
                        notificationToken,
                        timeZone: getTimeZone(),
                        timeZoneOffset: getTimeZoneOffset(),
                    });
                    return true;
                } else if (userPreferences && userPreferences.ref) {
                    // Remove token if it exists
                    updateDoc(userPreferences.ref, {
                        notificationToken: "",
                        timeZone: getTimeZone(),
                        timeZoneOffset: getTimeZoneOffset(),
                        sendNotifications: false,
                    });
                    return false;
                }
            }

            async function cleanNotifications() {
                deleteToken(setNotification);
                if (userPreferences && userPreferences.ref) {
                    updateDoc(userPreferences.ref, {
                        notificationToken: "",
                    });
                }
            }

            if (sendNotifications) {
                setupNotifications();
            } else {
                cleanNotifications();
            }
        },
        [collectionRefs, userPreferences]
    );

    const removeItemFromShoppingList = React.useCallback(
        (item: string) => {
            if (collectionRefs) {
                const doc: any = {};
                doc[item] = deleteField();
                updateDoc(collectionRefs.ingredientsInShoppingList, doc);
                setNotification(`${item} removed from shopping list`);
                logEvent("Remove Item from Shopping List.");
            }
        },
        [collectionRefs, logEvent]
    );

    const clearShoppingList = React.useCallback(() => {
        if (collectionRefs) {
            deleteDoc(collectionRefs.ingredientsInShoppingList);
            setNotification(`Shopping list cleared.`);
            logEvent("Shopping list cleared.");
        }
    }, [collectionRefs, logEvent]);

    const allAvailableCuisines = [
        "Indian",
        "Italian",
        "Chinese",
        "Japanese",
        "Thai",
        "Mexican",
        "American",
        "Viennese",
        "French",
        "German",
        "Spanish",
        "Greek",
        "Irish",
        "British",
        "Mediterranean",
        "Caribbean",
        "African",
        "Argentinian",
        "Brazilian",
        "Cajun",
        "Cuban",
        "Dutch",
    ];

    const getCurrentMealOptionsForTheDay = React.useCallback(
        (dayOfWeek: string) => {
            const weeklyMealMap = weeklyMealMapDoc || {};
            const keys = Object.keys(weeklyMealMap);
            return keys
                .filter((key) => weeklyMealMap[key].dayOfWeek === dayOfWeek)
                .map((key) => weeklyMealMap[key]);
        },
        [weeklyMealMapDoc]
    );

    const getSuggestedMealsForTheDay = React.useCallback(
        (dayOfWeek: string, existingSuggestions: WeeklyMealOptions[]) => {
            // Suggests meals for given day of week
            // Identify what MealTimes are available for the day
            const currentMealOptionsForTheDay =
                getCurrentMealOptionsForTheDay(dayOfWeek);
            const mealsToFindByMealTime = currentMealOptionsForTheDay.reduce(
                (acc: { [key: string]: number }, cur) => {
                    const meal = mealNameToMeal[cur.name];
                    if (meal) {
                        // Mark the meal time occupied if there is a meal for that time
                        for (const mealTime of Object.keys(meal.mealTimes)) {
                            if (acc[mealTime] === 1) {
                                delete acc[mealTime];
                                // Don't count same meal for two meal times
                                break;
                            }
                        }
                    }

                    return acc;
                },
                {
                    Breakfast: 1,
                    Lunch: 1,
                    Dinner: 1,
                }
            );

            const weeklyMealsMap = weeklyMealMapDoc || {};

            const existingSuggestionsMap = existingSuggestions.reduce(
                (acc: { [key: string]: WeeklyMealOptions }, cur) => {
                    acc[cur.name] = cur;
                    return acc;
                },
                {}
            );

            // For each mealsToFindByMealTime, find the meals that have the mealTime and not already planned
            const getMealCandidates = (meals: Meal[]): Meal[] =>
                meals.filter((meal) => {
                    // Filter out meals already planned for the week
                    if (
                        weeklyMealsMap[meal.name] ||
                        existingSuggestionsMap[meal.name]
                    ) {
                        return false;
                    }

                    // Filter out meals that don't have matching mealtime in mealsToFindByMealTime
                    let matchesMealTime = false;
                    for (const mealTime of Object.keys(meal.mealTimes)) {
                        if (
                            mealsToFindByMealTime[mealTime] &&
                            meal.mealTimes[mealTime]
                        ) {
                            matchesMealTime = true;
                            break;
                        }
                    }

                    return matchesMealTime;
                });

            const sortedCandidates = getMealCandidates(meals).sort(
                getMealComparer(ingredientsInShoppingList)
            );

            const suggestions: Meal[] = [];
            // Get one breakfast meal
            const breakFast = sortedCandidates.find(
                (c) => c.mealTimes["Breakfast"]
            );
            let remainingCandidates = breakFast
                ? sortedCandidates.filter((c) => c.name !== breakFast.name)
                : sortedCandidates;

            if (breakFast) {
                suggestions.push(breakFast);
            }

            // Get one lunch meal
            const lunch = remainingCandidates.find((c) => c.mealTimes["Lunch"]);
            remainingCandidates = lunch
                ? remainingCandidates.filter((c) => c.name !== lunch.name)
                : remainingCandidates;
            if (lunch) {
                suggestions.push(lunch);
            }

            // Get one dinner meal
            const dinner = remainingCandidates.find(
                (c) => c.mealTimes["Dinner"]
            );
            remainingCandidates = dinner
                ? remainingCandidates.filter((c) => c.name !== dinner.name)
                : remainingCandidates;

            if (dinner) {
                suggestions.push(dinner);
            }

            return {
                suggestions: [
                    ...existingSuggestions,
                    ...suggestions.map((meal) => {
                        return { name: meal.name, dayOfWeek };
                    }),
                ],
                hasMore: remainingCandidates.length > 0,
                dayOfWeek,
            };
        },
        [
            getCurrentMealOptionsForTheDay,
            ingredientsInShoppingList,
            mealNameToMeal,
            meals,
            weeklyMealMapDoc,
        ]
    );

    const [notification, setNotification] = React.useState("");
    const ourState: AppProps = {
        preferences: userPreferences,
        loading,
        error,
        user,
        meals,
        ingredientsInShoppingList,
        allIngredients,
        allAvailableCuisines,
        weeklyMealNames,
        weeklyMealMap: weeklyMealMapDoc || {},
        weeklyMeals,
        mealNameToLastMadeSince,
        mealNameToMeal,
        updateSendNotifications,
        setShowIntro,
        addItemsToShoppingList,
        removeItemFromShoppingList,
        addMeal,
        deleteMeal,
        updateMeal,
        onUpdateWeeklyMealOption,
        markAsMadeToday,
        populateShoppingList,
        setNotification,
        getMealOptionsForTheDay: getCurrentMealOptionsForTheDay,
        getSuggestedMealsForTheDay,
        clearShoppingList,
    };

    const [showFeedbackForm, setShowFeedbackForm] = React.useState(false);

    const showFeedbackCallback = React.useCallback(() => {
        setShowFeedbackForm(true);
        logEvent("Show Feedback Form.");
    }, [logEvent]);

    return (
        <Container>
            <ResponsiveAppBar
                user={user}
                setShowIntro={setShowIntro}
                userPreferences={userPreferences}
                showFeedback={showFeedbackCallback}
                updateSendNotifications={updateSendNotifications}
            />
            {loading && <div>Loading...</div>}
            {error && (
                <div>
                    Message:{error.message} ErrorName: {error.name}
                </div>
            )}
            {!loading && !error && (
                <Container maxWidth="sm" key="root-container">
                    <MainScreen {...ourState} />
                </Container>
            )}
            <Drawer
                anchor="top"
                onClose={() => setShowFeedbackForm(false)}
                open={showFeedbackForm}>
                <Alert
                    variant="outlined"
                    severity="success"
                    style={{ marginTop: "10px" }}>
                    Thanks for using Meal to cook. We appreciate any feedback
                    that you have. Please email us at{" "}
                    <a href="mailto:mealtocook@outlook.com?subject=Feedback for MealToCook">
                        mealtocook@outlook.com{" "}
                    </a>
                    . We will get back to you as soon as possible.
                </Alert>
            </Drawer>
            <Snackbar
                open={!!notification}
                autoHideDuration={6000}
                onClose={() => setNotification("")}
                message={notification}
            />
            {confirmationProps && <ConfirmationBar {...confirmationProps} />}
        </Container>
    );
}
