import * as React from "react";
import Button from "@mui/material/Button";
import {
    AppProps,
    getMealComparer,
    getMealTimeOrder,
    Meal,
    WeekDayOrder,
} from "../../interfaces";
import {
    Autocomplete,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Help } from "../Help";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import IosShareIcon from "@mui/icons-material/IosShare";
import { MealTimeNames } from "../../interfaces";
import DailyMenu from "./DailyMenu";

const help = () => (
    <Help
        title="HOW IT WORKS"
        messages={["Shows your weekly menu."]}
        iconMessages={[
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                }}>
                <EventBusyIcon
                    sx={{ color: "#8B0000" }}
                    style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">
                    Remove meal from weekly menu.
                </Typography>
            </div>,
        ]}></Help>
);

export default function WeeklyMeals(props: AppProps) {
    const {
        meals,
        weeklyMealNames,
        weeklyMealMap,
        mealNameToMeal,
        ingredientsInShoppingList,
        onUpdateWeeklyMealOption,
    } = props;

    const [selectedDay, setSelectedDay] = React.useState("");
    const [searchedMealText, setSearchMealText] = React.useState("");
    const [mealNames] = React.useState(meals.map((meal) => meal.name));

    const onAddMealToWeek = React.useCallback(() => {
        if (mealNameToMeal[searchedMealText]) {
            onUpdateWeeklyMealOption({
                name: searchedMealText,
                dayOfWeek: selectedDay || "Monday",
            });
            setSearchMealText("");
        }
    }, [
        mealNameToMeal,
        searchedMealText,
        onUpdateWeeklyMealOption,
        selectedDay,
    ]);

    const sortWeeklyMeals = React.useCallback(
        (meals: Meal[], ingredients: string[]) => {
            const comparer = getMealComparer(ingredients);
            return meals.sort((a, b) => {
                const aDay = WeekDayOrder[weeklyMealMap[a.name].dayOfWeek];
                const bDay = WeekDayOrder[weeklyMealMap[b.name].dayOfWeek];
                if (aDay === bDay) {
                    const aMealTimeOrder = getMealTimeOrder(a.mealTimes);
                    const bMealTimeOrder = getMealTimeOrder(b.mealTimes);
                    if (aMealTimeOrder === bMealTimeOrder) {
                        return comparer(a, b);
                    }
                    return aMealTimeOrder - bMealTimeOrder;
                }
                return aDay - bDay;
            });
        },
        [weeklyMealMap]
    );

    const sortedFilteredMeals = React.useMemo(() => {
        const meals = weeklyMealNames
            .filter((item) => mealNameToMeal[item])
            .map((m) => mealNameToMeal[m]);
        return sortWeeklyMeals(meals, ingredientsInShoppingList);
    }, [
        ingredientsInShoppingList,
        mealNameToMeal,
        sortWeeklyMeals,
        weeklyMealNames,
    ]);

    const onShare = React.useCallback(() => {
        const title = `Weekly Meals`;
        const shareData: ShareData = {
            title,
            text: [title]
                .concat(
                    sortedFilteredMeals.map((meal) => {
                        const { name, dayOfWeek } = weeklyMealMap[meal.name];
                        const { mealTimes } = meal;
                        const mealTimeStr =
                            " (" +
                                MealTimeNames.filter(
                                    (name) => mealTimes[name]
                                ).join(", ") +
                                ")" || "";
                        return `${dayOfWeek} - ${mealTimeStr} - ${name}`;
                    })
                )
                .join("\n"),
        };
        navigator.share(shareData);
    }, [sortedFilteredMeals, weeklyMealMap]);

    return (
        <Stack direction="column" spacing={"10px"}>
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={mealNames}
                value={searchedMealText}
                onChange={(e, value) => value && setSearchMealText(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Search Meal to add to Weekly Meal Plan"
                        onChange={(e) => setSearchMealText(e.target.value)}
                    />
                )}
            />
            <Stack direction="row" spacing={"10px"}>
                <Button
                    disabled={!mealNameToMeal[searchedMealText]}
                    variant="contained"
                    onClick={onAddMealToWeek}>
                    Add
                </Button>
                {help()}
                {(navigator as any).share && sortedFilteredMeals.length > 0 && (
                    <Tooltip title="Share">
                        <Button
                            onClick={onShare}
                            variant="outlined"
                            endIcon={<IosShareIcon />}>
                            Share
                        </Button>
                    </Tooltip>
                )}
            </Stack>
            <DailyMenu appProps={props} onSelectDayOfWeek={setSelectedDay} />
        </Stack>
    );
}
