import { DocumentReference, FirestoreError } from "@firebase/firestore";
import { format, formatDistance } from "date-fns";
import firebase from "firebase/compat/app";

export const WeekDayOrder: { [key: string]: number } = {
    "Monday": 0,
    "Tuesday": 1,
    "Wednesday": 2,
    "Thursday": 3,
    "Friday": 4,
    "Saturday": 5,
    "Sunday": 6,
    "": 7
}

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getTimeZoneOffset = () => (new Date()).getTimezoneOffset();

export const Weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const MealTimeOrder: { [key: string]: number } = {
    "Breakfast": 0,
    "Lunch": 1,
    "Dinner": 2,
    "": 3
}

export const getMealTimeOrder = (mealTimes: MealTimes): number => {
    if (mealTimes["Breakfast"]) return 0;
    if (mealTimes["Lunch"]) return 1;
    if (mealTimes["Dinner"]) return 2;
    return 3;
}


export interface Meal {
    name: string;
    mealTimes: MealTimes;
    ingredients: string[];
    cuisine: string;
    vegetarian: boolean;
    vegan: boolean;
    nonVegetarian: boolean;
    url?: string;
    lastMadeDate?: Date;
}
const global_cache: { [key: string]: any } = {};
export function useGlobal(key: string, value: any) {
    if (!global_cache[key]) {
        global_cache[key] = value;
    }
    return global_cache[key]
}

export function setGlobal(key: string, value: any) {
    global_cache[key] = value;
}

export const MealTimeNames = [
    "Breakfast",
    "Lunch",
    "Dinner",
];

export enum MealTimeEnum {
    "Breakfast" = "Breakfast",
    "Lunch" = "Lunch",
    "Dinner" = "Dinner",
}

export type MealTimes = {
    [key: string]: boolean;
};

export const DefaultMealTime: MealTimes = {
    Breakfast: true,
    Lunch: true,
    Dinner: true,
};

export interface UserPreferences {
    showIntro: boolean;
    timeZone: string;
    timeZoneOffset: number;
    sendNotifications: boolean;
    notificationToken: string;
    ref?: DocumentReference;
}
export type WeeklyMealOptions = {
    name: string;
    dayOfWeek: string,
}
export type MealMap = { [key: string]: Meal };
export type StringMap = { [key: string]: string };
export type WeeklyMealMap = { [key: string]: WeeklyMealOptions };
export interface MealSuggestionResults { dayOfWeek: string; suggestions: WeeklyMealOptions[]; hasMore: boolean };

export interface AppProps {
    preferences: UserPreferences;
    loading: boolean;
    error?: FirestoreError;
    user: firebase.User | null;
    meals: Meal[];
    ingredientsInShoppingList: string[];
    allIngredients: string[];
    allAvailableCuisines: string[];
    weeklyMealNames: string[];
    weeklyMealMap: WeeklyMealMap;
    weeklyMeals: Meal[];
    mealNameToLastMadeSince: { [key: string]: string };
    mealNameToMeal: { [key: string]: Meal };
    setShowIntro: (show: boolean) => void;
    onUpdateWeeklyMealOption: (options: WeeklyMealOptions) => void;
    addItemsToShoppingList: (items: string[]) => void;
    removeItemFromShoppingList: (item: string) => void;
    addMeal: (meal: Meal) => void;
    deleteMeal: (mealName: string, autoConfirm?: boolean) => void;
    updateMeal: (originalName: string, meal: Meal) => void;
    markAsMadeToday: (mealName: string) => void;
    populateShoppingList: () => void;
    setNotification: (notification: string) => void;
    getMealOptionsForTheDay: (dayOfWeek: string) => WeeklyMealOptions[];
    getSuggestedMealsForTheDay: (dayOfWeek: string, existingSuggestions: WeeklyMealOptions[]) => MealSuggestionResults;
    clearShoppingList: () => void;
    updateSendNotifications: (send: boolean) => void;
}

export const getUnavailableIngredients = (
    ingredients: string[],
    ingredientsInShoppingList: string[]
) =>
    ingredients.filter(
        (ingredient) => ingredientsInShoppingList.includes(ingredient)
    );

export const getItemsNotInShoppingList = (
    ingredients: string[],
    ingredientsInShoppingList: string[]
) =>
    ingredients.filter(
        (ingredient) =>
            !ingredientsInShoppingList.includes(ingredient)
    );

export const getItemsInShoppingList = (
    ingredients: string[],
    ingredientsInShoppingList: string[]
) =>
    ingredients.filter(
        (ingredient) =>
            ingredientsInShoppingList.includes(ingredient)
    );



/* To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */

// eslint-disable-next-line no-extend-native
export function removeSpecialCharsAndSetTitleCase(str: string) {
    const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
    const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
    const wordSeparators = /([ :–—-])/

    return (str || "")
        .replace(/[~*/%<>\\$'"[\]&]/g, "")
        .split(wordSeparators)
        .map(function (current, index, array) {
            if (
                /* Check for small words */
                current.search(smallWords) > -1 &&
                /* Skip first and last word */
                index !== 0 &&
                index !== array.length - 1 &&
                /* Ignore title end and subtitle start */
                array[index - 3] !== ':' &&
                array[index + 1] !== ':' &&
                /* Ignore small words that start a hyphenated phrase */
                (array[index + 1] !== '-' ||
                    (array[index - 1] === '-' && array[index + 1] === '-'))
            ) {
                return current.toLowerCase()
            }

            /* Ignore intentional capitalization */
            if (current.substr(1).search(/[A-Z]|\../) > -1) {
                return current
            }

            /* Ignore URLs */
            if (array[index + 1] === ':' && array[index + 2] !== '') {
                return current
            }

            /* Capitalize the first letter */
            return current.replace(alphanumericPattern, function (match) {
                return match.toUpperCase()
            })
        })
        .join('')
}

export function getLastMadeSince(lastMadeDate?: Date) {
    return lastMadeDate
        ? formatDistance(lastMadeDate, new Date(), {
            includeSeconds: false,
            addSuffix: true,
        })
        : "";
}

export const compareBaseOnMealTime = (a: Meal, b: Meal) => {
    const compareOnMealTime = (mealTime: string) => {
        if (a.mealTimes[mealTime] && !b.mealTimes[mealTime]) {
            return -1;
        }

        if (b.mealTimes[mealTime] && !a.mealTimes[mealTime]) {
            return 1;
        }

        return 0;
    }

    let c = compareOnMealTime('Breakfast');
    if (c !== 0) {
        return c;
    }

    c = compareOnMealTime('Lunch');
    if (c !== 0) {
        return c;
    }
    c = compareOnMealTime('Dinner');
    if (c !== 0) {
        return c;
    }
}

export const getMealComparer = (ingredientsInShoppingList: string[]) => (a: Meal, b: Meal) => {
    /*
     0. Meal time
     1. The one that was made the least recently
     2. The one had fewer unavailable ingredients
     3. The one had fewer items to add to the shopping list
     4. Sort by name
     */

    if (a.lastMadeDate && b.lastMadeDate) {
        return a.lastMadeDate.getDate() - b.lastMadeDate.getDate();
    }
    if (a.lastMadeDate) {
        return 1;
    }

    if (b.lastMadeDate) {
        return -1;
    }

    const unavailableItemsA = getUnavailableIngredients(
        a.ingredients,
        ingredientsInShoppingList
    );
    const unavailableItemsB = getUnavailableIngredients(
        b.ingredients,
        ingredientsInShoppingList
    );

    if (unavailableItemsA.length !== unavailableItemsB.length) {
        return unavailableItemsA.length - unavailableItemsB.length;
    }

    return a.name.localeCompare(b.name);
}

export function sortMeals(meals: Meal[], ingredientsInShoppingList: string[]) {
    const comparer = getMealComparer(ingredientsInShoppingList);
    return [...meals].sort(comparer);
}

export function getPrimaryIconColor(grayed?: boolean) { return (grayed ? "gray" : "#ef4136;"); }
export function getSecondaryIconColor(grayed?: boolean) { return (grayed ? "gray" : "#c72b20;"); }
export function getTodaysDayOfWeek(): string { return format(new Date(), "EEEE"); }

export const isNotificationSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window