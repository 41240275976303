import firebase from "firebase/compat/app";
import "firebase/compat/messaging";


const messaging = firebase.messaging();
export async function getToken(setNotification: (notification: string) => void) {
    try {
        const currentToken = await messaging.getToken({ vapidKey: "BGwiGICdqz26kVQKuCgIBMOzMGMm-JHE9QBF0Bc5gRaoW3-7tgETqZTvT-PsKOmSaeYx4woSA6WezcoUMd96rSU" });

        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            return currentToken;
        } else {
            // Show permission request UI
            return "";
            // ...
        }
    }
    catch (err: any) {
        console.log('An error occurred while retrieving token. ', err);
        setNotification("An error occurred while retrieving token. Please email mealtocook@outlook.com for support. Error Message: " + err);
        return "";
    }
}

export async function deleteToken(setNotification: (notification: string) => void) {
    try {
        const token = await getToken(setNotification);
        if (token) { await messaging.deleteToken(); }
    } catch (err) {
        console.log("Error deleting token:", err);
    }
}