import EventBusyIcon from "@mui/icons-material/EventBusy";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Friday from "./friday";
import Monday from "./monday";
import Saturday from "./saturday";
import Sunday from "./sunday";
import Thursday from "./thursday";
import Tuesday from "./tuesday";
import Wednesday from "./wednesday";

export const RemoveScheduleIcon = <EventBusyIcon sx={{ color: "#8B0000" }} />;

export const AddScheduleIcon = <CalendarMonthIcon sx={{ color: "green" }} />;

export const WeekdayIcons: { [key: string]: JSX.Element } = {
    Monday: <Monday />,
    Tuesday: <Tuesday />,
    Wednesday: <Wednesday />,
    Thursday: <Thursday />,
    Friday: <Friday />,
    Saturday: <Saturday />,
    Sunday: <Sunday />,
    RemoveSchedule: RemoveScheduleIcon,
    "": AddScheduleIcon,
    AddSchedule: AddScheduleIcon,
};

export const GrayedWeekdayIcons: { [key: string]: JSX.Element } = {
    Monday: <Monday grayed={true} />,
    Tuesday: <Tuesday grayed={true} />,
    Wednesday: <Wednesday grayed={true} />,
    Thursday: <Thursday grayed={true} />,
    Friday: <Friday grayed={true} />,
    Saturday: <Saturday grayed={true} />,
    Sunday: <Sunday grayed={true} />,
    RemoveSchedule: RemoveScheduleIcon,
    "": AddScheduleIcon,
    AddSchedule: AddScheduleIcon,
};
