import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { AppProps, Meal, isNotificationSupported } from "../../interfaces";
import {
    BottomNavigation,
    BottomNavigationAction,
    Container,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MealSelector } from "../sub-components/MealSelector";
import { CuisineSelector } from "../sub-components/CuisineSelector";
import { useNavigate } from "react-router-dom";
import { NotificationToggle } from "./NotificationToggle";

interface StepDetails {
    id: string;
    label: string;
    description: string;
    content?: () => React.ReactNode;
}

const globalPreferredCuisines = new Set<string>();

function getSteps(props: AppProps): StepDetails[] {
    const defaultNotifications = [
        {
            id: "start",
            label: "Let us get started!!",
            description:
                "Let us get started. We will help you prepare your weekly meal plan in easy steps.",
        },
        {
            id: "pick-cuisine",
            label: "Pick cuisines",
            description: "Please pick cuisines that you cook regularly.",
            content: () => {
                const initialSelected = props.meals.reduce(
                    (acc: Set<string>, curr: Meal) => {
                        acc.add(curr.cuisine);
                        return acc;
                    },
                    globalPreferredCuisines
                );

                return (
                    <CuisineSelector
                        appProps={props}
                        suggestedCount={2}
                        initialSelected={Array.from(initialSelected)}
                        onCuisinesSelected={(cuisines: string[]) => {
                            globalPreferredCuisines.clear();
                            cuisines.forEach((cuisine) =>
                                globalPreferredCuisines.add(cuisine)
                            );
                        }}
                    />
                );
            },
        },
        {
            id: "pick-breakfast",
            label: "Pick 7 to 10 Breakfast meals.",
            description:
                "Pick 7 to 10 Breakfast meals that your family loves to cook. If you don't see an option below feel free to add your own.",
            content: () => (
                <MealSelector
                    appProps={props}
                    cuisineNames={Array.from(globalPreferredCuisines)}
                    mealTimes={["Breakfast"]}
                    suggestedCount={7}
                />
            ),
        },
        {
            id: "pick-lunch",
            label: "Pick 7 to 10 Lunch and Dinner meals.",
            description:
                "Pick 7 to 10 Lunch and Dinner meals that your family like to cook. If you don't see an option below feel free to add your own..",
            content: () => (
                <MealSelector
                    appProps={props}
                    mealTimes={["Lunch", "Dinner"]}
                    cuisineNames={Array.from(globalPreferredCuisines)}
                    suggestedCount={7}
                />
            ),
        },
        {
            id: "pick-ui-details",
            label: "How it works?",
            description: "The app is divided in four sections.",
            content: () => (
                <Container>
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label={"Weekly Meal Plan"}
                            icon={<CalendarMonthIcon />}
                        />
                    </BottomNavigation>
                    <Typography variant="body2">
                        1) Weekly Meal Plan: You can create a weekly meal plan
                        from your Meals. We prepared a weekly plan already
                        please take a look and customize it to your liking.
                    </Typography>
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label="Family Meals"
                            icon={<MenuBookIcon />}
                        />
                    </BottomNavigation>
                    <Typography variant="body2">
                        2) Family Meals: This shows the meals to cook based on
                        what you have in your kitchen. We have populated it
                        based on your selections above.
                    </Typography>
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label={`Shopping List`}
                            icon={<ShoppingCartIcon />}
                        />
                    </BottomNavigation>
                    <Typography variant="body2">
                        3) Shopping List: This is your shopping list.
                    </Typography>
                </Container>
            ),
        },
    ];

    if (isNotificationSupported()) {
        defaultNotifications.push({
            id: "toggle-notifications",
            label: "Send daily reminders?",
            description:
                "Would you like to receive daily reminders for your meals to get a head start in prep?",
            content: () => <NotificationToggle {...props} />,
        });
    }

    return defaultNotifications;
}

export default function SetupStepper(props: AppProps) {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = React.useMemo<StepDetails[]>(() => getSteps(props), [props]);

    function handleNext(): void {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    React.useEffect(() => {
        if (activeStep === steps.length) {
            props.setShowIntro(false);
            navigate("/weekly-meal-plan");
        }
    }, [activeStep, navigate, props, steps]);

    return (
        <Box className="appRoot">
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            {step.content && step.content()}
                            <Box sx={{ mb: 2 }}>
                                <Container>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}>
                                        {index === steps.length - 1
                                            ? "Finish"
                                            : index === 0
                                            ? "Start"
                                            : "Continue"}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}>
                                        Back
                                    </Button>
                                </Container>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper
                    square
                    elevation={0}
                    sx={{ p: 3 }}
                    style={{ height: "100%" }}>
                    <Typography>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                    </Button>
                </Paper>
            )}
        </Box>
    );
}
