import { getFirestore, DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, WithFieldValue, Firestore, WriteBatch, writeBatch, doc } from "@firebase/firestore";
import { Meal, MealMap, removeSpecialCharsAndSetTitleCase, StringMap, UserPreferences, WeeklyMealMap, WeeklyMealOptions } from "./interfaces";
import firebase from "firebase/compat/app";

const mealsConverter: FirestoreDataConverter<MealMap> = {
    toFirestore(meals: WithFieldValue<MealMap>): DocumentData {
        const keys = Object.keys(meals);
        return keys.reduce((acc: any, key) => {
            const meal = meals[key] as Meal;
            acc[key] = {
                name: meal.name, mealTimes: meal.mealTimes, ingredients: meal.ingredients, cuisine: meal.cuisine, vegetarian: meal.vegetarian, vegan: meal.vegan, nonVegetarian: meal.nonVegetarian, url: meal.url || "", lastMadeDate: meal.lastMadeDate || ""
            };
            return acc;
        }, {});
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): MealMap {
        const mealMapData = snapshot.data(options);
        const keys = Object.keys(mealMapData);
        const mealMap: MealMap = {};

        keys.forEach((key: string) => {
            const meal = mealMapData[key];
            mealMap[key] = {
                name: removeSpecialCharsAndSetTitleCase(meal.name),
                mealTimes: meal.mealTimes,
                ingredients: meal.ingredients.map((ingredient: string) => removeSpecialCharsAndSetTitleCase(ingredient)),
                cuisine: meal.cuisine,
                vegetarian: meal.vegetarian,
                vegan: meal.vegan,
                nonVegetarian: meal.nonVegetarian,
                url: meal.url || "",
                lastMadeDate: meal.lastMadeDate ? meal.lastMadeDate.toDate() : undefined
            };
        });

        return mealMap;
    },
};

const weeklyMealsConverter: FirestoreDataConverter<WeeklyMealMap> = {
    toFirestore(meals: WithFieldValue<WeeklyMealMap>): DocumentData {
        const keys = Object.keys(meals);
        return keys.reduce((acc: any, key) => {
            const option = meals[key] as WeeklyMealOptions;
            acc[key] = {
                name: option.name, dayOfWeek: option.dayOfWeek
            };
            return acc;
        }, {});
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): WeeklyMealMap {
        const weeklyMealMapData = snapshot.data(options);
        const keys = Object.keys(weeklyMealMapData);
        const weeklyMealMap: WeeklyMealMap = {};

        keys.forEach((key: string) => {
            const weeklyMeal = weeklyMealMapData[key];
            if (typeof weeklyMeal === "string") {
                weeklyMealMap[key] = {
                    name: weeklyMeal,
                    dayOfWeek: ""
                };
            }
            else {
                weeklyMealMap[key] = {
                    name: removeSpecialCharsAndSetTitleCase(weeklyMeal.name),
                    dayOfWeek: weeklyMeal.dayOfWeek
                };
            }
        });

        return weeklyMealMap;
    },
};
const stringMapConverter: FirestoreDataConverter<StringMap> = {
    toFirestore(doc: WithFieldValue<StringMap>): DocumentData {
        return doc;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): StringMap {
        return snapshot.data(options);

    },
};

const preferenceConverter: FirestoreDataConverter<UserPreferences> = {
    toFirestore(doc: WithFieldValue<UserPreferences>): DocumentData {
        return { showIntro: doc.showIntro, sendNotifications: doc.sendNotifications, notificationToken: doc.notificationToken, timeZone: doc.timeZone };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): UserPreferences {
        const data = snapshot.data(options);
        return {
            showIntro: data.showIntro === undefined ? true : data.showIntro,
            sendNotifications: !!data.sendNotifications,
            notificationToken: data.notificationToken,
            timeZone: data.timeZone,
            timeZoneOffset: data.timeZoneOffset,
            ref: snapshot.ref
        };
    },
};

export function getBatch(db: Firestore): WriteBatch {
    return writeBatch(db);
}

export function getCollectionRefs() {
    const db = getFirestore(firebase.app());
    const uid = firebase.auth().currentUser?.uid;
    const mealsMap = doc(db, `users/${uid}/docs/mealsMap`).withConverter(mealsConverter);

    const ingredientsInShoppingList = doc(db, `users/${uid}/docs/shoppingListItem`).withConverter(stringMapConverter);
    const userPreference = doc(db, `users/${uid}/docs/userPreferences`).withConverter(preferenceConverter);
    const weeklyMeals = doc(db, `users/${uid}/docs/weeklyMeals`).withConverter(weeklyMealsConverter);

    return {
        getBatch: () => getBatch(db),
        mealsMap,
        ingredientsInShoppingList,
        userPreference,
        weeklyMeals
    }
}
