import * as React from "react";
import { Box } from "@mui/material";
import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

interface CuisinesCheckboxGroupProps {
    initialSelection: string[];
    availableCuisines: string[];
    onChange: (cuisines: string[]) => void;
}
export const CuisinesCheckboxGroup = (props: CuisinesCheckboxGroupProps) => {
    const { availableCuisines, initialSelection, onChange } = props;
    const [cuisines, setCuisines] = React.useState<string[]>(initialSelection);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const name = event.target.name;
        let filteredCuisines = cuisines;
        if (checked) {
            filteredCuisines = [...cuisines, name];
        } else {
            filteredCuisines = cuisines.filter((cuisine) => cuisine !== name);
        }
        onChange(filteredCuisines);
        setCuisines(filteredCuisines);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <FormControl component="fieldset" variant="standard">
                <FormGroup style={{ flexDirection: "row", display: "flex" }}>
                    {availableCuisines.map((cuisine) => (
                        <FormControlLabel
                            key={`cuisine-${cuisine}`}
                            control={
                                <Checkbox
                                    checked={cuisines.includes(cuisine)}
                                    onChange={handleChange}
                                    name={cuisine}
                                />
                            }
                            label={cuisine}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        </Box>
    );
};
