import { SvgIcon } from "@mui/material";
import {
    getPrimaryIconColor,
    getSecondaryIconColor,
} from "../../../interfaces";

export default function Wednesday(props: any) {
    const primaryColor = getPrimaryIconColor(props.grayed);
    const secondaryColor = getSecondaryIconColor(props.grayed);

    return (
        <SvgIcon {...props}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 116.61">
                <defs>
                    <style>{`.cls-wednesday-1{fill:gray;}.cls-wednesday-2{fill:#fff;}.cls-wednesday-2,.cls-wednesday-3,.cls-wednesday-4{fill-rule:evenodd;}.cls-wednesday-3{fill:${primaryColor}}.cls-wednesday-4{fill:${secondaryColor};}.cls-wednesday-5{fill:#1a1a1a;}`}</style>
                </defs>
                <title>week-day-wednesday</title>
                <path
                    className="cls-wednesday-1"
                    d="M111.36,116.61H11.52A11.57,11.57,0,0,1,0,105.09V40H122.88v65.07a11.56,11.56,0,0,1-11.52,11.52Z"
                />
                <path
                    className="cls-wednesday-2"
                    d="M12.75,112.92h97.38a9.1,9.1,0,0,0,9.06-9.06V44.94H3.69v58.92a9.08,9.08,0,0,0,9.06,9.06Z"
                />
                <path
                    className="cls-wednesday-3"
                    d="M11.52,6.67h99.84a11.57,11.57,0,0,1,11.52,11.52V44.94H0V18.19A11.56,11.56,0,0,1,11.52,6.67Zm24.79,9.75A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Zm49.79,0a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-wednesday-4"
                    d="M86.1,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.06,11.06,0,0,1,7.74-3.16Zm0,1.79a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-wednesday-4"
                    d="M36.31,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.08,11.08,0,0,1,7.74-3.16Zm0,1.79A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-wednesday-5"
                    d="M80.54,4.56C80.54,2,83,0,86.1,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-wednesday-5"
                    d="M30.75,4.56C30.75,2,33.24,0,36.31,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-wednesday-5"
                    d="M45,65.23h9.17l-4.3,27.4H38.37l-2-10.08h-.53l-2,10.08H22.41l-4.3-27.4h9.17l2.06,15.3h.26l3.16-15.3h6.71l3.11,15.3h.27L45,65.23Zm28.9,17.06H65.08v3.33H75.83v7H56.31V65.23h19.3l-1.1,7H65.08v3.69h8.77v6.36Zm5.7,10.34V65.23H91.83q7.41,0,10.17,3.15t2.77,10.55q0,7.39-2.77,10.55T91.83,92.63ZM92,72.24H88.32V85.62H92a6,6,0,0,0,2.61-.42c.54-.28.81-.91.81-1.91V74.57c0-1-.27-1.63-.81-1.91A6,6,0,0,0,92,72.24Z"
                />
            </svg>
        </SvgIcon>
    );
}
