import * as React from "react";

import NavigationBar from "./NavigationBar";
import IngredientsList from "./sub-components/IngredientsList";
import FamilyMeals from "./FamilyMeals";
import { AppProps } from "../interfaces";
import { Routes, Route, useLocation } from "react-router-dom";
import WeeklyMeals from "./sub-components/WeeklyMeals";
import SetupStepper from "./setup/SetupStepper";
import { Login } from "../Login";
function MainScreen(props: AppProps) {
    const {
        meals,
        ingredientsInShoppingList,
        weeklyMealNames,
        addItemsToShoppingList,
        removeItemFromShoppingList,
        clearShoppingList,
    } = props;

    let location = useLocation();

    return (
        <>
            {location.pathname !== "/setup" &&
                location.pathname !== "/login" && (
                    <NavigationBar
                        weeklyMealNames={weeklyMealNames}
                        meals={meals}
                        ingredientsInShoppingList={ingredientsInShoppingList}
                    />
                )}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<WeeklyMeals {...props} />} />
                <Route
                    path="/weekly-meal-plan"
                    element={<WeeklyMeals {...props} />}
                />
                <Route
                    path="/family-meals"
                    element={<FamilyMeals {...props} />}
                />
                <Route
                    path="/shopping-list"
                    element={
                        <IngredientsList
                            items={ingredientsInShoppingList}
                            listSource={"ShoppingList"}
                            onRemove={removeItemFromShoppingList}
                            onAdd={addItemsToShoppingList}
                            onClear={clearShoppingList}
                        />
                    }
                />
                <Route path="/setup" element={<SetupStepper {...props} />} />
            </Routes>
        </>
    );
}

export default MainScreen;
