import { MealMap } from "./interfaces";

export const GlobalMeals: MealMap = {
    "Boiled Egg Sandwich": {
        "name": "Boiled Egg Sandwich",
        "mealTimes": {
            "Breakfast": true,

            "Dinner": false,

            "Lunch": false
        },
        "ingredients": [
            "Eggs",
            "Bread"
        ],
        "cuisine": "American",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,

        "url": ""
    },
    "Lemon Rice": {
        "name": "Lemon Rice",
        "mealTimes": {
            "Dinner": false,

            "Breakfast": false,

            "Lunch": true
        },
        "ingredients": [
            "Boiled Rice",
            "Lime Juice",
            "Curry Leaves",
            "Green Chili",
            "Peanuts"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Potato Sandwich": {
        "name": "Potato Sandwich",

        "mealTimes": {
            "Lunch": false,


            "Dinner": false,
            "Breakfast": true
        },
        "ingredients": [
            "Onion",
            "Green Chilli",
            "Cilantro",
            "Boiled Potatoes"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",
    },
    "Lauki": {
        "name": "Lauki",
        "mealTimes": {


            "Lunch": true,
            "Dinner": true,
            "Breakfast": false
        },
        "ingredients": [
            "Lauki",
            "Tomato",
            "Green Chilli"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",
    },
    "Dosa Sambhar": {
        "name": "Dosa Sambhar",
        "mealTimes": {
            "Breakfast": false,

            "Dinner": true,
            "Lunch": true,

        },
        "ingredients": [
            "Dosa Batter",
            "Sambhar Ingredients; Tuar Dal",
            "Onion",
            "Tomato",
            "Squash",
            "Eggplant",
            "Okra",
            "Curry Leaves"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Shahi Paneer": {
        "name": "Shahi Paneer",
        "mealTimes": {


            "Lunch": true,
            "Breakfast": false,
            "Dinner": true
        },
        "ingredients": [
            "Matar Paneer (Pea Paneer)",
            "Peas",
            "Paneer",
            "Green Chilli",
            "Ginger",
            "Onion",
            "Garlic",
            "Tomatoes",
            "Cilantro",
            "Milk",
            "Cashews"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Uthpam": {
        "name": "Uthpam",

        "mealTimes": {

            "Dinner": false,

            "Lunch": false,
            "Breakfast": true
        },
        "ingredients": [
            "Semolina",
            "Yogurt",
            "Green Chilli",
            "Cilantro",
            "Bell Pepper",
            "Cabbage"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Idli Sambhar": {
        "name": "Idli Sambhar",
        "mealTimes": {
            "Dinner": false,


            "Lunch": false,
            "Breakfast": true
        },
        "ingredients": [
            "Idli Batter"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Lentil Veggie": {
        "name": "Lentil Veggie",

        "mealTimes": {
            "Lunch": false,
            "Breakfast": false,


            "Dinner": true
        },
        "ingredients": [
            "Lentil",
            "Onion",
            "Ginger",
            "Green Chili",
            "Cilantro",
            "Tomato"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Kadi Rice": {
        "name": "Kadi Rice",
        "mealTimes": {
            "Dinner": true,
            "Lunch": true,

            "Breakfast": false,

        },
        "ingredients": [
            "Yogurt",
            "Besan",
            "Green Chilli",
            "Peas",
            "Ginger",
            "Garlic",
            "Boiled Rice"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Tomato Cheese Sandwich": {
        "name": "Tomato Cheese Sandwich",

        "mealTimes": {
            "Breakfast": true,

            "Lunch": false,

            "Dinner": false
        },
        "ingredients": [
            "Bread",
            "Tomatoes",
            "Cheese"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Mac Cheese": {
        "name": "Mac Cheese",

        "mealTimes": {
            "Dinner": false,
            "Breakfast": false,

            "Lunch": true,

        },
        "ingredients": [
            "Boiled Pasta",
            "Cheese",
            "Broccoli",
            "Bell Pepper",
            "Corn"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Pav Bhaji": {
        "name": "Pav Bhaji",
        "mealTimes": {
            "Breakfast": false,

            "Dinner": true,

            "Lunch": true
        },
        "ingredients": [
            "Pav",
            "Cauliflower",
            "Potatoes",
            "Bell Pepper",
            "Garlic",
            "Ginger",
            "Onion Carrot",
            "Cabbage",
            "Green Chili"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Aloo Gobhi( Cauliflower Potatoes)": {
        "name": "Aloo Gobhi( Cauliflower Potatoes)",
        "mealTimes": {
            "Lunch": true,


            "Dinner": true,
            "Breakfast": false
        },
        "ingredients": [
            "Potato Cauliflower Peas",
            "Tomato",
            "Green Chili",
            "Cilantro",
            "Ginger"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Oatmeal": {
        "name": "Oatmeal",

        "mealTimes": {


            "Breakfast": true,
            "Dinner": false,
            "Lunch": false
        },
        "ingredients": [
            "Oats",
            "Milk",
            "Almond",
            "Raisin",
            "Cardamom"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Gatte Ki Sabji": {
        "name": "Gatte Ki Sabji",
        "mealTimes": {


            "Dinner": true,
            "Lunch": true,
            "Breakfast": false
        },
        "ingredients": [
            "Besan",
            "Yogurt",
            "Cilantro"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Upma": {
        "name": "Upma",

        "mealTimes": {
            "Dinner": false,
            "Breakfast": true,
            "Lunch": false,


        },
        "ingredients": [
            "Semolina",
            "Onion",
            "Green Chilli",
            "Bell Pepper",
            "Tomato",
            "Curry Leaves",
            "Peanuts"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Rajma(Red Kidney Beans)": {
        "name": "Rajma(Red Kidney Beans)",

        "mealTimes": {

            "Lunch": true,
            "Dinner": true,
            "Breakfast": false,

        },
        "ingredients": [
            "Boiled Rajma(kidney Beans)",
            "Onion",
            "Green Chilli",
            "Ginger",
            "Garlic",
            "Tomatoes",
            "Cilantro"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Potato Beans": {
        "name": "Potato Beans",
        "mealTimes": {

            "Breakfast": false,
            "Lunch": true,
            "Dinner": false,

        },
        "ingredients": [
            "Potato (Aloo)",
            "Green Beans",
            "Green Chili",
            "Tomato"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Palak Paratha": {
        "name": "Palak Paratha",
        "mealTimes": {

            "Lunch": true,
            "Dinner": true,
            "Breakfast": false,

        },
        "ingredients": [
            "Wheat Flour",
            "Spinach",
            "Green Chilli"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Spinach Kadi": {
        "name": "Spinach Kadi",
        "mealTimes": {

            "Lunch": false,
            "Dinner": true,
            "Breakfast": false,

        },
        "ingredients": [
            "Yogurt",
            "Besan",
            "Spinach"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Besan Cheela (Crepe)": {
        "name": "Besan Cheela (Crepe)",
        "mealTimes": {
            "Breakfast": true,

            "Lunch": false,
            "Dinner": false,

        },
        "ingredients": [
            "Besan",
            "Green Chilli",
            "Ginger",
            "Bell Pepper",
            "Cilantro"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Aloo Baigan (Potato Eggplant)": {
        "name": "Aloo Baigan (Potato Eggplant)",
        "mealTimes": {
            "Dinner": true,
            "Lunch": true,

            "Breakfast": false,

        },
        "ingredients": [
            "Potato (Aloo)",
            "Eggplant",
            "Green Chilli",
            "Cilantro"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Paneer Paratha": {
        "name": "Paneer Paratha",

        "mealTimes": {

            "Breakfast": true,
            "Lunch": true,

            "Dinner": true
        },
        "ingredients": [
            "Paneer",
            "Green Chili",
            "Wheat Flour Dough",
            "Ginger",
            "Cilantro"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Poha": {
        "name": "Poha",

        "mealTimes": {
            "Breakfast": true,


            "Dinner": false,
            "Lunch": false
        },
        "ingredients": [
            "Poha",
            "Onion",
            "Curry Leaves",
            "Green Chilli",
            "Peanut"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Chole": {
        "name": "Chole",
        "mealTimes": {
            "Lunch": true,


            "Dinner": true,
            "Breakfast": false
        },
        "ingredients": [
            "Onion",
            "Green Chilli",
            "Ginger",
            "Garlic",
            "Cilantro",
            "Boiled Chole (Garbenzo Beans)",
            "Tomato"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Moong Dal Khichri": {
        "name": "Moong Dal Khichri",
        "mealTimes": {


            "Breakfast": false,
            "Dinner": true,
            "Lunch": false
        },
        "ingredients": [
            "Moong Dal",
            "Onion",
            "Peas",
            "Ginger",
            "Green Chili"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Palak Paneer (Spinach Cottage Cheese)": {
        "name": "Palak Paneer (Spinach Cottage Cheese)",
        "mealTimes": {

            "Lunch": true,

            "Dinner": true,
            "Breakfast": false
        },
        "ingredients": [
            "Spinach",
            "Paneer",
            "Green Chili",
            "Ginger",
            "Onion",
            "Yogurt"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Veggie Pasta": {
        "name": "Veggie Pasta",

        "mealTimes": {
            "Lunch": true,

            "Breakfast": false,
            "Dinner": false,

        },
        "ingredients": [
            "Boiled Pasta",
            "Carrot",
            "Corn",
            "Broccoli",
            "Bell Pepper",
            "Cheese",
            "Tomatoes"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Curd Rice": {
        "name": "Curd Rice",
        "mealTimes": {
            "Dinner": false,


            "Lunch": true,
            "Breakfast": false
        },
        "ingredients": [
            "Boiled Rice",
            "Yougurt",
            "Urad Dal",
            "Peanuts",
            "Green Chilli",
            "Curry Leaves"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Aloo Gajar( Potato Carrot)": {
        "name": "Aloo Gajar( Potato Carrot)",
        "mealTimes": {

            "Breakfast": false,
            "Lunch": true,
            "Dinner": false,

        },
        "ingredients": [
            "Potato (Aloo)",
            "Carrot",
            "Ginger",
            "Green Chilli",
            "Cilantro",
            "Tomato",
            "Peas"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Moong Dal Cheela (Crepe)": {
        "name": "Moong Dal Cheela (Crepe)",
        "mealTimes": {
            "Lunch": false,


            "Breakfast": true,
            "Dinner": false
        },
        "ingredients": [
            "Moong Dal Paste",
            "Green Chilli",
            "Ginger",
            "Paneer",
            "Cilantro",
            "Bell Pepper"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Veggie Taco": {
        "name": "Veggie Taco",

        "mealTimes": {
            "Dinner": true,
            "Lunch": true,

            "Breakfast": false,

        },
        "ingredients": [
            "Taco",
            "Salsa",
            "Avocado",
            "Sour Cream",
            "Black Beans",
            "Boiled Rice"
        ],
        "cuisine": "Indian",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Bhindi Masala (Spice Okra)": {
        "name": "Bhindi Masala (Spice Okra)",
        "mealTimes": {
            "Lunch": true,

            "Breakfast": false,

            "Dinner": false
        },
        "ingredients": [
            "Okra",
            "Green Chilli"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Omelette": {
        "name": "Omelette",

        "mealTimes": {


            "Breakfast": true,
            "Lunch": false,
            "Dinner": false
        },
        "ingredients": [
            "Eggs",
            "Onion",
            "Green Chilli",
            "Ginger"
        ],
        "cuisine": "Indian",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",

    },
    "Chilaquiles": {
        "name": "Chilaquiles",
        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Toasted corn tortilla (Totopos)", "Hot sauce", "Chicken", "Chorizo", "Beef", "Eggs", "Cheese", "Coriander", "Onion", "Beans"],
        "cuisine": "Mexican",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "url": "",
    },
    "Huevos Rancheros (Ranch Eggs)": {
        "name": "Huevos Rancheros (Ranch Eggs)",
        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Eggs", "Onion", "Corn Tortillas", "Cheese", "Tomato", "Beans"],
        "cuisine": "Mexican",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "url": "",
    },
    "Machaca": {
        "name": "Machaca",
        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Eggs", "Onion", "Corn Tortillas", "Cheese", "Tomato", "Beans"],
        "cuisine": "Mexican",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "url": "",
    },
    "Discada": {
        "name": "Discada",
        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Eggs", "Onion", "Corn Tortillas", "Cheese", "Tomato", "Beans"],
        "cuisine": "Mexican",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "url": "",
    },
    "Chicken Tacos": {
        "name": "Chicken Tacos",

        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Onion", "Chicken", "Corn Tortillas", "Avocado", "Cheese", "Tomato", "Beans", "Coriender", "Sour Cream", "Lettuce"],
        "cuisine": "Mexican",
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "url": "",
    },
    "Chilaquiles (Vegetarian)": {
        "name": "Chilaquiles (Vegetarian)",
        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Toasted corn tortilla (Totopos)", "Hot sauce", "Chorizo", "Cheese", "Coriander", "Onion", "Beans"],
        "cuisine": "Mexican",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": true,
        "url": "",
    },
    "Tacos (Vegetarian)": {
        "name": "Tacos",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Onion", "Corn Tortillas", "Cheese", "Tomato", "Beans", "Coriender", "Sour Cream", "Lettuce", "Tomato Sauce"],
        "cuisine": "Mexican",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": true,
        "url": "",
    },
    "Pad Thai": {
        "name": "Pad Thai",
        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Rice Noodles", "Tomato"],
        "cuisine": "Thai",
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",
    },
    "Fried Rice": {
        "name": "Fried Rice",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "cuisine": "Thai",
        "ingredients": ["Rice", "Onion"],
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "url": "",
    },
    "Pancake": {
        "name": "Pancake",

        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Flour", "Milk", "Eggs", "Sugar", "Baking Powder", "Baking Soda", "Salt", "Butter", "Vanilla", "Cinnamon", "Strawberry"],
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "cuisine": "American",
        "url": "",
    },
    "Taco salad": {
        "name": "Taco salad",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Onion", "Corn Tortillas", "Cheese", "Tomato", "Beans", "Coriender", "Sour Cream", "Lettuce", "Tomato Sauce"],
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": true,
        "cuisine": "Mexican",
        "url": "",
    },
    "Rice Bowl": {
        "name": "Rice Bowl",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Rice", "Onion", "Chicken", "Tomato", "Beans", "Coriender", "Sour Cream", "Lettuce", "Tomato Sauce"],
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": true,
        "cuisine": "Thai",
        "url": "",
    },
    "French Omlette": {
        "name": "French Omlette",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Eggs", "Butter"],
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "cuisine": "French",
        "url": "",
    },
    "Lasagna": {
        "name": "Lasagna",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Pasta Sause", "Lasanga noodles", "Ricotta cheese", "Meat sause", "Mozzarella cheese", "Parmesan cheese"],
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": true,
        "cuisine": "Italian",
        "url": "",
    },
    "Scrambled Eggs": {
        "name": "Scrambled Eggs",

        "mealTimes": { "Breakfast": true, "Lunch": false, "Dinner": false },
        "ingredients": ["Eggs", "Milk", "Butter"],
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "cuisine": "American",
        "url": "",
    },
    "Philly Cheesesteak": {
        "name": "Philly Cheesesteak",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Onion", "Peppers", "Steak", "Seasoning", "Provolone Cheese", "Buns"],
        "vegetarian": false,
        "vegan": false,
        "nonVegetarian": true,
        "cuisine": "American",
        "url": "",
    },
    "Brussels Sprouts": {
        "name": "Brussels Sprouts",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Brussels Sprouts", "Olive Oil", "Walnuts", "Dried cranberries", "Dijon mustared", "Maple Syrup"],
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "cuisine": "American",
        "url": "",
    },
    "Broccoli Cheese Soup": {
        "name": "Broccoli Cheese Soup",

        "mealTimes": { "Breakfast": false, "Lunch": true, "Dinner": true },
        "ingredients": ["Butter", "Onion", "Garlic", "Broccoli", "All Purpose Flour", "Milk", "Half and Half", "Vegetable Broth", "Carrot"],
        "vegetarian": true,
        "vegan": false,
        "nonVegetarian": false,
        "cuisine": "American",
        "url": "",

    }
}

// export const GlobalMealNames = Object.keys(GlobalMeals).map(m => {
//     return {
//         inputValue: m,
//         title: m
//     }
// });

export const GlobalMealNames = Object.keys(GlobalMeals);