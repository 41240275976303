import * as React from "react";
import MealEditor from "./sub-components/MealEditor";
import {
    Meal,
    sortMeals,
    WeeklyMealOptions,
    MealMap,
    WeeklyMealMap,
} from "../interfaces";
import { MealItem } from "./sub-components/MealItem";
import { MealFilter } from "./sub-components/MealFilter";
import { Alert, List, Typography, Stack } from "@mui/material";
import { Help } from "./Help";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export interface FamilyMealsProps {
    allIngredients: string[];
    allAvailableCuisines: string[];
    ingredientsInShoppingList: string[];
    meals: Meal[];
    weeklyMealNames: string[];
    mealNameToMeal: MealMap;
    weeklyMealMap: WeeklyMealMap;
    onUpdateWeeklyMealOption: (options: WeeklyMealOptions) => void;
    addItemsToShoppingList: (ingredient: string[]) => void;
    addMeal: (meal: Meal) => void;
    updateMeal: (name: string, meal: Meal) => void;
    deleteMeal: (name: string) => void;
}

const help = () => (
    <Help
        title="HOW IT WORKS"
        messages={[
            "The meals are sorted based on availability of " +
                "ingredients in the kitchen and something that " +
                "you have not made recently. You can expand the " +
                "meal to see more details.",
        ]}
        iconMessages={[
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                }}>
                <CalendarMonthIcon
                    sx={{ color: "green" }}
                    style={{ marginRight: "10px" }}
                />
                <Typography variant="body2">
                    Add meal to weekly menu.
                </Typography>
            </div>,
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                }}>
                <EditIcon style={{ marginRight: "10px" }} />
                <Typography variant="body2">
                    Edit meal name, ingredients etc.
                </Typography>
            </div>,

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                }}>
                <DeleteIcon style={{ marginRight: "10px" }} />
                <Typography variant="body2">Delete the meal.</Typography>
            </div>,
        ]}></Help>
);

export default function FamilyMeals(props: FamilyMealsProps) {
    const {
        allIngredients,
        meals,
        allAvailableCuisines,
        ingredientsInShoppingList,
        mealNameToMeal,
        addItemsToShoppingList,
        addMeal,
        updateMeal,
        deleteMeal,
    } = props;
    const [startEditMeal, setStartEditMeal] = React.useState(false);
    const [mealToEdit, setMealToEdit] = React.useState<Meal | undefined>(
        undefined
    );

    const [filteredMeals, setFilteredMeals] = React.useState<Meal[]>(meals);

    const onEditMeal = React.useCallback(
        (name: string) => {
            const meal = meals.find((meal) => meal.name === name);
            if (meal) {
                setMealToEdit(meal);
                setStartEditMeal(true);
            }
        },
        [meals]
    );

    const onUpdateMeals = React.useCallback(
        (meal: Meal) => {
            setStartEditMeal(false);
            mealToEdit && updateMeal(mealToEdit.name, meal);
        },
        [updateMeal, mealToEdit]
    );

    const sortedFilteredMeals = React.useMemo(() => {
        return sortMeals(filteredMeals, ingredientsInShoppingList);
    }, [filteredMeals, ingredientsInShoppingList]);

    const cancelCallback = React.useCallback(() => {
        setStartEditMeal(false);
    }, []);

    return (
        <>
            {startEditMeal && mealToEdit && (
                <MealEditor
                    mealNameToMeal={mealNameToMeal}
                    allAvailableCuisines={allAvailableCuisines}
                    existingMeal={mealToEdit}
                    allIngredients={allIngredients}
                    Cancel={cancelCallback}
                    addOrUpdate={onUpdateMeals}
                />
            )}
            {!startEditMeal && (
                <Stack direction="column" spacing={"10px"}>
                    <MealFilter
                        mealNameToMeal={mealNameToMeal}
                        selectAll={true}
                        allIngredients={allIngredients}
                        allAvailableCuisines={allAvailableCuisines}
                        addMeal={addMeal}
                        meals={meals}
                        setFilteredMeals={setFilteredMeals}
                        initialCollapsed={true}
                        allowCreateMeal={true}
                    />
                    {sortedFilteredMeals.length > 0 && help()}
                    {sortedFilteredMeals.length === 0 && (
                        <Alert style={{ marginTop: "10px" }} severity="info">
                            Please change filter or create meals.
                        </Alert>
                    )}
                    <List dense={true}>
                        {sortedFilteredMeals.map((meal) => (
                            <MealItem
                                key={`${meal.name}-item`}
                                meal={meal}
                                ingredientsInShoppingList={
                                    ingredientsInShoppingList
                                }
                                onAddItemsToShoppingList={
                                    addItemsToShoppingList
                                }
                                weeklyMealOptions={
                                    props.weeklyMealMap[meal.name] || {
                                        name: meal.name,
                                        dayOfWeek: "",
                                    }
                                }
                                onUpdateWeeklyMealOption={
                                    props.onUpdateWeeklyMealOption
                                }
                                onDeleteMeal={deleteMeal}
                                onEditMeal={onEditMeal}
                                initialCollapsed={true}
                            />
                        ))}
                    </List>
                </Stack>
            )}
        </>
    );
}
