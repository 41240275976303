import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { GiMeal } from "react-icons/gi";
import { Button, Container } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import firebase from "firebase/compat/app";
import { ConfirmationBar } from "./sub-components/ConfirmationBar";
import { UserPreferences, isNotificationSupported } from "../interfaces";

const signOut = () => {
    firebase
        .auth()
        .signOut()
        .then(() => {
            window.localStorage.clear();
            window.location.reload();
        });
};

export interface ResponsiveAppBarProps {
    user: firebase.User | null;
    setShowIntro: (show: boolean) => void;
    userPreferences: UserPreferences;
    updateSendNotifications: (send: boolean) => void;
    showFeedback?: () => void;
}

const ResponsiveAppBar = (props: ResponsiveAppBarProps) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [confirmAnonymousLogout, setConfirmAnonymousLogout] =
        React.useState(false);

    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };

    const settings = [
        "Feedback",
        "Setup",
        props.userPreferences.sendNotifications
            ? "Do not send notifications"
            : "Send notifications",
        "Logout",
    ];

    if (!isNotificationSupported()) {
        delete settings[2];
    }

    const handleCloseUserMenu = (e: any) => {
        const target = e.target.id;
        if (target === "Logout") {
            if (props.user?.isAnonymous) {
                setConfirmAnonymousLogout(true);
            } else {
                signOut();
            }
        } else if (target === "Setup") {
            props.setShowIntro(true);
        } else if (target === "Send notifications") {
            props.updateSendNotifications(true);
        } else if (target === "Do not send notifications") {
            props.updateSendNotifications(false);
        } else if (target === "Feedback") {
            props.showFeedback && props.showFeedback();
        }
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="sm">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
                        <GiMeal size="30" />
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}>
                        Meal to cook
                    </Typography>

                    {props.user && (
                        <Box sx={{ flexGrow: 0 }}>
                            {props.showFeedback && (
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        props.showFeedback &&
                                        props.showFeedback()
                                    }>
                                    Feedback
                                </Button>
                            )}
                            <Tooltip
                                title={`Open settings for ${
                                    props.user.displayName || "Guest"
                                }`}>
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}>
                                    <AccountCircleIcon
                                        htmlColor="white"
                                        fontSize="large"
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}>
                                {settings.map((setting) => (
                                    <MenuItem
                                        key={setting}
                                        onClick={handleCloseUserMenu}>
                                        <Typography
                                            textAlign="center"
                                            id={setting}>
                                            {setting}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    )}
                </Toolbar>

                <ConfirmationBar
                    open={confirmAnonymousLogout}
                    onClose={() => setConfirmAnonymousLogout(false)}
                    actionLabel="Sign out"
                    onAction={signOut}
                    message="You are logged in as Guest, you will not be able to login again and loose all data. Are you sure you want to logout? "
                />
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
