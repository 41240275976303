import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import React from "react";
import { removeSpecialCharsAndSetTitleCase } from "../../interfaces";

export interface AutoCompleteWithAddNewProps {
    initialValue: string;
    options: string[];
    label: string;
    onChange: (value: string) => void;
}
type AutoCompleteType = { inputValue: string; title: string };
const filter = createFilterOptions<AutoCompleteType>();

export const AutoCompleteWithAddNew = (props: AutoCompleteWithAddNewProps) => {
    const { initialValue, options, onChange, label } = props;
    const [autoCompleteOptions] = React.useState(
        options.map((option) => ({ inputValue: option, title: option }))
    );

    const [currentValue, setCurrentValue] = React.useState<AutoCompleteType>({
        inputValue: initialValue,
        title: initialValue,
    });

    return (
        <Autocomplete
            id="free-solo-demo"
            freeSolo
            style={{ marginTop: "15px" }}
            multiple={false}
            options={autoCompleteOptions}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                    (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Create "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            value={currentValue.inputValue}
            onChange={(e, value) => {
                if (value) {
                    const name = removeSpecialCharsAndSetTitleCase(
                        typeof value === "string" ? value : value.inputValue
                    );
                    onChange(name);
                }
            }}
            inputValue={currentValue.inputValue}
            onInputChange={(e, value) => {
                value = removeSpecialCharsAndSetTitleCase(value);
                setCurrentValue({ inputValue: value, title: value });
            }}
            onBlur={() => {
                if (currentValue.inputValue) {
                    onChange(currentValue.inputValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={"outlined"}
                    size="small"
                    label={label}
                />
            )}
        />
    );
};
