import { SvgIcon } from "@mui/material";
import {
    getPrimaryIconColor,
    getSecondaryIconColor,
} from "../../../interfaces";

export default function Friday(props: any) {
    const primaryColor = getPrimaryIconColor(props.grayed);
    const secondaryColor = getSecondaryIconColor(props.grayed);

    return (
        <SvgIcon {...props}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 116.61">
                <title>week-day-friday</title>
                <defs>
                    <style>{`.cls-friday-1{fill:gray;}.cls-friday-2{fill:#fff;}.cls-friday-2,.cls-friday-3,.cls-friday-4{fill-rule:evenodd;}.cls-friday-3{fill:${primaryColor}}.cls-friday-4{fill:${secondaryColor};}.cls-friday-5{fill:#1a1a1a;}`}</style>
                </defs>
                <path
                    className="cls-friday-1"
                    d="M111.36,116.61H11.52A11.57,11.57,0,0,1,0,105.09V40H122.88v65.07a11.56,11.56,0,0,1-11.52,11.52Z"
                />
                <path
                    className="cls-friday-2"
                    d="M12.75,112.92h97.38a9.1,9.1,0,0,0,9.06-9.06V44.94H3.69v58.92a9.08,9.08,0,0,0,9.06,9.06Z"
                />
                <path
                    className="cls-friday-3"
                    d="M11.52,6.67h99.84a11.57,11.57,0,0,1,11.52,11.52V44.94H0V18.19A11.56,11.56,0,0,1,11.52,6.67Zm24.79,9.75A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Zm49.79,0a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-friday-4"
                    d="M86.1,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.06,11.06,0,0,1,7.74-3.16Zm0,1.79a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-friday-4"
                    d="M36.31,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.08,11.08,0,0,1,7.74-3.16Zm0,1.79A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-friday-5"
                    d="M80.54,4.56C80.54,2,83,0,86.1,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-friday-5"
                    d="M30.75,4.56C30.75,2,33.24,0,36.31,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-friday-5"
                    d="M47.76,82.57H40.07V93.06H31V64.8H49.57L48.44,72H40.07v3.79h7.69v6.74ZM78.28,93.06h-10l-3.7-8.41H62.68v8.41h-9V64.8H67.88q9.72,0,9.72,9.9,0,6.78-4.2,9l4.88,9.4ZM62.68,72v5.65h2.08a6.27,6.27,0,0,0,2.38-.34c.5-.23.74-.75.74-1.56V73.93c0-.81-.24-1.33-.74-1.56A6.3,6.3,0,0,0,64.76,72Zm20.12,21V64.8h9V93.06Z"
                />
            </svg>
        </SvgIcon>
    );
}
