import { SvgIcon } from "@mui/material";
import {
    getPrimaryIconColor,
    getSecondaryIconColor,
} from "../../../interfaces";

export default function Monday(props: any) {
    const primaryColor = getPrimaryIconColor(props.grayed);
    const secondaryColor = getSecondaryIconColor(props.grayed);

    return (
        <SvgIcon {...props}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 116.61">
                <defs>
                    <style>{`.cls-monday-1{fill:gray;}.cls-monday-2{fill:#fff;}.cls-monday-2,.cls-monday-3,.cls-monday-4{fill-rule:evenodd;}.cls-monday-3{fill:${primaryColor}}.cls-monday-4{fill:${secondaryColor};}.cls-monday-5{fill:#1a1a1a;}`}</style>
                </defs>
                <title>week-day-monday</title>
                <path
                    className="cls-monday-1"
                    d="M111.36,116.61H11.52A11.57,11.57,0,0,1,0,105.09V40H122.88v65.07a11.56,11.56,0,0,1-11.52,11.52Z"
                />
                <path
                    className="cls-monday-2"
                    d="M12.75,112.92h97.38a9.1,9.1,0,0,0,9.06-9.06V44.94H3.69v58.92a9.08,9.08,0,0,0,9.06,9.06Z"
                />
                <path
                    className="cls-monday-3"
                    d="M11.52,6.67h99.84a11.57,11.57,0,0,1,11.52,11.52V44.94H0V18.19A11.56,11.56,0,0,1,11.52,6.67Zm24.79,9.75A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Zm49.79,0a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-monday-4"
                    d="M86.1,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.06,11.06,0,0,1,7.74-3.16Zm0,1.79a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-monday-4"
                    d="M36.31,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.08,11.08,0,0,1,7.74-3.16Zm0,1.79A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-monday-5"
                    d="M80.54,4.56C80.54,2,83,0,86.1,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-monday-5"
                    d="M30.75,4.56C30.75,2,33.24,0,36.31,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-monday-5"
                    d="M26,92.63H16.8l1.66-27.41H29.91l3.42,14h.31l3.42-14H48.5l1.67,27.41H41l-.52-13.29h-.31L36.84,92.63H30.13L26.75,79.34h-.26L26,92.63ZM52.47,79q0-7.5,2.81-10.94t10.13-3.44q7.32,0,10.13,3.44T78.35,79a28.46,28.46,0,0,1-.59,6.27,11.57,11.57,0,0,1-2,4.43,8.25,8.25,0,0,1-4,2.76,19.34,19.34,0,0,1-6.31.88,19.38,19.38,0,0,1-6.31-.88,8.18,8.18,0,0,1-4-2.76,11.44,11.44,0,0,1-2-4.43A28.46,28.46,0,0,1,52.47,79Zm9.43-4.56v11.4h3.64a6.19,6.19,0,0,0,2.61-.41c.54-.28.81-.92.81-1.91V72.06H65.28a5.89,5.89,0,0,0-2.57.42c-.54.28-.81.92-.81,1.91ZM97.84,92.63,91.13,82.9a4.59,4.59,0,0,1-.44-2.11h-.17V92.63H81.75V65.22H90L96.7,75a4.54,4.54,0,0,1,.44,2.1h.18V65.22h8.76V92.63Z"
                />
            </svg>
        </SvgIcon>
    );
}
