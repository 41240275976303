// Import FirebaseAuth and firebase.
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Configure Firebase.
const config = {
    apiKey: "AIzaSyDDlJ31CcNdfifpr2TC8q_RYU09GwmMDq0",
    authDomain: "mealtocook.firebaseapp.com",
    projectId: "mealtocook",
    storageBucket: "mealtocook.appspot.com",
    messagingSenderId: "862155389933",
    appId: "1:862155389933:web:d886e10b62a6851e7a45e4",
    measurementId: "G-DPC56EQ869",
};
firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};

function SignInScreen() {
    return (
        <div>
            <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
            />
        </div>
    );
}

export default SignInScreen;
