import { SvgIcon } from "@mui/material";
import {
    getPrimaryIconColor,
    getSecondaryIconColor,
} from "../../../interfaces";

export default function Thursday(props: any) {
    const primaryColor = getPrimaryIconColor(props.grayed);
    const secondaryColor = getSecondaryIconColor(props.grayed);

    return (
        <SvgIcon {...props}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 116.61">
                <defs>
                    <style>{`.cls-thursday-1{fill:gray;}.cls-thursday-2{fill:#fff;}.cls-thursday-2,.cls-thursday-3,.cls-thursday-4{fill-rule:evenodd;}.cls-thursday-3{fill:${primaryColor}}.cls-thursday-4{fill:${secondaryColor};}.cls-thursday-5{fill:#1a1a1a;}`}</style>
                </defs>
                <title>week-day-thursday</title>
                <path
                    className="cls-thursday-1"
                    d="M111.36,116.61H11.52A11.57,11.57,0,0,1,0,105.09V40H122.88v65.07a11.56,11.56,0,0,1-11.52,11.52Z"
                />
                <path
                    className="cls-thursday-2"
                    d="M12.75,112.92h97.38a9.1,9.1,0,0,0,9.06-9.06V44.94H3.69v58.92a9.08,9.08,0,0,0,9.06,9.06Z"
                />
                <path
                    className="cls-thursday-3"
                    d="M11.52,6.67h99.84a11.57,11.57,0,0,1,11.52,11.52V44.94H0V18.19A11.56,11.56,0,0,1,11.52,6.67Zm24.79,9.75A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Zm49.79,0a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-thursday-4"
                    d="M86.1,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.06,11.06,0,0,1,7.74-3.16Zm0,1.79a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-thursday-4"
                    d="M36.31,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.08,11.08,0,0,1,7.74-3.16Zm0,1.79A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-thursday-5"
                    d="M80.54,4.56C80.54,2,83,0,86.1,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-thursday-5"
                    d="M30.75,4.56C30.75,2,33.24,0,36.31,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-thursday-5"
                    d="M44.62,71.91H38.26V92.3H29.49V71.91H23.13v-7H44.62v7ZM63,92.3V82.61H56.46V92.3H47.69V64.9h8.77v9.69H63V64.9h8.77V92.3ZM85,64.9V85.46h3.12a4.47,4.47,0,0,0,2.28-.41c.41-.28.61-.92.61-1.91V64.9h8.77V80.29a30.31,30.31,0,0,1-.48,6,8.65,8.65,0,0,1-1.8,3.85,6.81,6.81,0,0,1-3.59,2.2A23.43,23.43,0,0,1,88,93a23.15,23.15,0,0,1-5.9-.61,6.82,6.82,0,0,1-3.58-2.2,8.74,8.74,0,0,1-1.79-3.85,30.2,30.2,0,0,1-.49-6V64.9Z"
                />
            </svg>
        </SvgIcon>
    );
}
