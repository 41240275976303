import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import { AppProps } from "../../interfaces";

export function NotificationToggle(props: AppProps) {
    const {
        updateSendNotifications: updateShowNotifications,
        preferences: { sendNotifications },
    } = props;
    const handleChange = React.useCallback(
        (event: { target: { checked: boolean } }) => {
            updateShowNotifications(event.target.checked);
        },
        [updateShowNotifications]
    );
    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="body2">
                We can send you notifications every day to remind you to start
                prepping you for next days meals in the night, and a second
                notification in the morning to get started. This will avoid the
                need for opening the app every day.
            </Typography>
            <FormControlLabel
                control={
                    <Switch
                        onChange={handleChange}
                        checked={sendNotifications}
                    />
                }
                label={`Click to ${
                    sendNotifications ? "disable" : "enable"
                } notifications`}
            />
        </Stack>
    );
}
