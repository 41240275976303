import * as React from "react";
import { Box } from "@mui/material";
import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { MealTimes } from "../../interfaces";

export interface MealTimeCheckboxGroupProps {
    mealTimes: MealTimes;
    onChange: (mealTimes: MealTimes) => void;
}
export function MealTimeCheckboxGroup(props: MealTimeCheckboxGroupProps) {
    const state = props.mealTimes;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const doc: any = {};
        doc[event.target.name] = event.target.checked;
        props.onChange({
            ...state,
            ...doc,
        });
    };
    const { Breakfast, Lunch, Dinner } = state;

    return (
        <Box sx={{ display: "flex" }}>
            <FormControl component="fieldset" variant="standard">
                <FormGroup style={{ flexDirection: "row", display: "flex" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Breakfast}
                                onChange={handleChange}
                                name="Breakfast"
                            />
                        }
                        label="Breakfast"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Lunch}
                                onChange={handleChange}
                                name="Lunch"
                            />
                        }
                        label="Lunch"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Dinner}
                                onChange={handleChange}
                                name="Dinner"
                            />
                        }
                        label="Dinner"
                    />
                </FormGroup>
            </FormControl>
        </Box>
    );
}
