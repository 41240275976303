import { createTheme, ThemeProvider } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import React from "react";
import { Main } from "./Main";
import { BrowserRouter as Router } from "react-router-dom";

export default function App() {
    const darkTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: "light",
                    primary: {
                        main: lightGreen[700],
                    },
                    secondary: {
                        main: "#689f38",
                    },
                    info: {
                        main: "#689f38",
                    },
                    success: {
                        main: "#689f38",
                    },
                    background: {
                        default: "#689f38",
                        paper: "#f1f8e9",
                    },
                },
            }),
        []
    );

    return (
        <ThemeProvider theme={darkTheme}>
            <Router>
                <Main />
            </Router>
        </ThemeProvider>
    );
}
