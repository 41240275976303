import * as React from "react";
import { Button, Snackbar } from "@mui/material";
export interface ConfirmationBarProps {
    open: boolean;
    message: string;
    actionLabel: string;
    onAction: () => void;
    onClose: () => void;
}

export function ConfirmationBar(props: ConfirmationBarProps) {
    const { open, onAction, onClose, message, actionLabel } = props;

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={onAction}>
                {actionLabel}
            </Button>
            <Button
                style={{ marginLeft: "10px" }}
                color="primary"
                size="small"
                variant="contained"
                onClick={onClose}>
                Cancel
            </Button>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={open}
            autoHideDuration={60000}
            onClose={onClose}
            message={message}
            action={action}
        />
    );
}
