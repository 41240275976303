import { Alert, SpeedDial, Typography } from "@mui/material";
import { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";

export const Help = (props: {
    title: string;
    messages: string[];
    iconMessages?: JSX.Element[];
}) => {
    const { messages, title, iconMessages } = props;
    const [open, setOpen] = useState(false);

    return (
        <SpeedDial
            ariaLabel={title}
            icon={<HelpIcon />}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            sx={{ position: "absolute", bottom: 16, right: 16 }}>
            {open && (
                <Alert
                    severity={"info"}
                    sx={{
                        position: "absolute",
                        bottom: 16,
                        right: 16,
                        width: "300px",
                    }}
                    onClose={() => setOpen(false)}>
                    {messages.map((message) => (
                        <Typography variant="body2">{message}</Typography>
                    ))}
                    {iconMessages &&
                        iconMessages.map((iconMessage, index) => iconMessage)}
                </Alert>
            )}
        </SpeedDial>
    );
};
