import { Stack, Typography, Paper } from "@mui/material";
import SignInScreen from "./Auth";

export function Login() {
    return (
        <Stack direction="column" spacing={"20px"}>
            <Paper
                variant="elevation"
                elevation={24}
                style={{
                    padding: "10px",
                    backgroundImage: `url(${"./cooking-background-15.jpeg"})`,
                }}>
                <SignInScreen />
                <Typography
                    variant="h4"
                    align="center"
                    style={{ color: "white" }}>
                    We do the planning, you enjoy the cooking.
                </Typography>
                <Typography
                    variant="body1"
                    align="center"
                    style={{ color: "white" }}>
                    Tell us about the meals you enjoy to cook and we will create
                    a weekly meal plan for you.
                </Typography>
            </Paper>
            <Typography variant="h6" align="center">
                Our story
            </Typography>
            <Typography
                style={{ marginTop: "10px" }}
                variant="body2"
                align="left"
                component="div">
                We are Neetu and Navneet. Every morning, we struggled with a
                question.
                <strong> What should we cook today? </strong> This question
                added lot of stress to our already busy mornings.
            </Typography>
            <Typography
                style={{ marginTop: "10px" }}
                variant="body2"
                align="left"
                component="div">
                We decided to do something about it and made this website to
                plan our week. Now it tells us exactly what we should cook. Our
                mornings are not stressful anymore. We hope you enjoy it as much
                as we do. Email us at{" "}
                <a href="mailto:mealtocook@outlook.com">
                    mealtocook@outlook.com
                </a>{" "}
                for any suggestions.{" "}
                <a href="https://www.privacypolicygenerator.info/live.php?token=udSrrvWI1VBDki4ETuKbFU4hrPMhlulz">
                    Privacy Policy
                </a>
            </Typography>
        </Stack>
    );
}
