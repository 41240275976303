import React from "react";
import { AppProps, Meal, MealTimes, MealMap } from "../../interfaces";
import { GlobalMealNames, GlobalMeals } from "../../globalMeals";
import { Chip, LinearProgress, Stack, Container } from "@mui/material";
import { AutoCompleteWithAddNew } from "./AutoCompleteWithAddNew";
import AddMealDialog from "./AddMealDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export interface MealSelectorProps {
    appProps: AppProps;
    mealTimes: string[];
    cuisineNames: string[];
    suggestedCount: number;
}

export function MealSelector(props: MealSelectorProps) {
    const { mealTimes, suggestedCount, appProps, cuisineNames } = props;
    const [showCreateMeal, setShowCreateMeal] = React.useState(false);

    const [counter, setCounter] = React.useState(0);

    const distinct = (meals: Meal[]) => {
        const map = meals.reduce((acc: MealMap, curr: Meal) => {
            acc[curr.name] = curr;
            return acc;
        }, {});

        return Object.keys(map).map((key) => map[key]);
    };
    const availableMeals = React.useMemo(
        () =>
            distinct(
                appProps.meals
                    .concat(GlobalMealNames.map((name) => GlobalMeals[name]))
                    .filter((meal: Meal) =>
                        mealTimes.some(
                            (mealTime) =>
                                meal.mealTimes[mealTime] &&
                                cuisineNames.includes(meal.cuisine)
                        )
                    )
            ),
        [appProps.meals, cuisineNames, mealTimes]
    );

    const [selectedMealNames, setSelectedMealNames] = React.useState<
        Set<string>
    >(new Set(appProps.meals.map((meal) => meal.name)));

    const progress = React.useMemo(
        () =>
            selectedMealNames.size >= suggestedCount
                ? 100
                : (selectedMealNames.size / suggestedCount) * 100,
        [selectedMealNames.size, suggestedCount]
    );
    const [searchText, setSearchText] = React.useState("");
    const sortMealsByName = (a: Meal, b: Meal) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    };
    const searchResults = React.useMemo((): Meal[] => {
        if (searchText.length === 0) {
            return availableMeals.sort(sortMealsByName);
        }
        return availableMeals
            .filter((meal) => {
                return (
                    !searchText ||
                    meal.name.toLowerCase().includes(searchText.toLowerCase())
                );
            })
            .sort(sortMealsByName);
    }, [availableMeals, searchText]);

    const availableMealNames = React.useMemo(
        () => availableMeals.map((meal) => meal.name),
        [availableMeals]
    );

    const handleMealClick = React.useCallback(
        (meal: Meal) => {
            const mealName = meal.name;
            const updatedSelectedMeals = new Set(selectedMealNames);
            if (updatedSelectedMeals.has(mealName)) {
                updatedSelectedMeals.delete(mealName);
                setSelectedMealNames(updatedSelectedMeals);

                appProps.deleteMeal(mealName, /** autoConfirm */ true);
            } else {
                updatedSelectedMeals.add(mealName);
                setSelectedMealNames(updatedSelectedMeals);

                appProps.addMeal(meal);
            }
        },
        [appProps, selectedMealNames]
    );

    const handleCreateMeal = React.useCallback(
        (name: string) => {
            if (appProps.mealNameToMeal[name]) {
                handleMealClick(appProps.mealNameToMeal[name]);
                setSearchText("");
            } else {
                const mealsMealTime: MealTimes = {};
                mealTimes.forEach((t) => (mealsMealTime[t] = true));
                const meal: Meal = {
                    name,
                    mealTimes: mealsMealTime,
                    ingredients: [],
                    cuisine: cuisineNames[0],
                    vegetarian: true,
                    vegan: true,
                    nonVegetarian: true,
                };
                handleMealClick(meal);
                setCounter(counter + 1);
                setShowCreateMeal(false);
            }
        },
        [
            appProps.mealNameToMeal,
            counter,
            cuisineNames,
            handleMealClick,
            mealTimes,
        ]
    );
    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="column">
                <LinearProgress variant="determinate" value={progress} />
                <AutoCompleteWithAddNew
                    key={counter}
                    initialValue={searchText}
                    options={availableMealNames}
                    label="Search or Add meals"
                    onChange={handleCreateMeal}
                />
            </Stack>
            <Container>
                <Chip
                    key="add-meal"
                    label="Create your own Meal"
                    onClick={() => {
                        setShowCreateMeal(true);
                    }}
                    icon={<AddCircleIcon />}
                    variant="filled"
                    color="info"
                />
                {searchResults.map((meal) => (
                    <Chip
                        key={meal.name}
                        style={{ margin: "5px" }}
                        label={meal.name}
                        onClick={() => handleMealClick(meal)}
                        variant="filled"
                        color={
                            selectedMealNames.has(meal.name)
                                ? "success"
                                : "default"
                        }
                    />
                ))}
            </Container>
            {showCreateMeal && (
                <AddMealDialog
                    onMealCreated={handleCreateMeal}
                    onClose={() => setShowCreateMeal(false)}
                />
            )}
        </Stack>
    );
}
