import { SvgIcon } from "@mui/material";
import {
    getPrimaryIconColor,
    getSecondaryIconColor,
} from "../../../interfaces";

export default function Saturday(props: any) {
    const primaryColor = getPrimaryIconColor(props.grayed);
    const secondaryColor = getSecondaryIconColor(props.grayed);

    return (
        <SvgIcon {...props}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 116.61">
                <defs>
                    <style>{`.cls-saturday-1{fill:gray;}.cls-saturday-2{fill:#fff;}.cls-saturday-2,.cls-saturday-3,.cls-saturday-4{fill-rule:evenodd;}.cls-saturday-3{fill:${primaryColor}}.cls-saturday-4{fill:${secondaryColor};}.cls-saturday-5{fill:#1a1a1a;}`}</style>
                </defs>
                <title>week-day-saturday</title>
                <path
                    className="cls-saturday-1"
                    d="M111.36,116.61H11.52A11.57,11.57,0,0,1,0,105.09V40H122.88v65.07a11.56,11.56,0,0,1-11.52,11.52Z"
                />
                <path
                    className="cls-saturday-2"
                    d="M12.92,112.92H110.3a9.09,9.09,0,0,0,9.06-9.06V44.94H3.86v58.92a9.09,9.09,0,0,0,9.06,9.06Z"
                />
                <path
                    className="cls-saturday-3"
                    d="M11.52,6.67h99.84a11.57,11.57,0,0,1,11.52,11.52V44.94H0V18.19A11.56,11.56,0,0,1,11.52,6.67Zm24.79,9.75A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Zm49.79,0a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-saturday-4"
                    d="M86.1,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.06,11.06,0,0,1,7.74-3.16Zm0,1.79a9.31,9.31,0,1,1-9.31,9.31,9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-saturday-4"
                    d="M36.31,14.63a11.11,11.11,0,1,1-7.85,3.26l.11-.1a11.08,11.08,0,0,1,7.74-3.16Zm0,1.79A9.31,9.31,0,1,1,27,25.73a9.31,9.31,0,0,1,9.31-9.31Z"
                />
                <path
                    className="cls-saturday-5"
                    d="M80.54,4.56C80.54,2,83,0,86.1,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-saturday-5"
                    d="M30.75,4.56C30.75,2,33.24,0,36.31,0s5.56,2,5.56,4.56V25.77c0,2.51-2.48,4.56-5.56,4.56s-5.56-2-5.56-4.56V4.56Z"
                />
                <path
                    className="cls-saturday-5"
                    d="M25.24,92.15l1.22-7.24a29.88,29.88,0,0,0,7.26,1,42.22,42.22,0,0,0,5.2-.26V83l-4-.35c-3.56-.32-6-.74-7.34-2.13s-2-3.44-2-6.16q0-5.61,2.44-7.72t8.26-2.1a46.49,46.49,0,0,1,10.53,1.09l-1.1,7c-2.72-.44-4.9-1.1-6.53-1.1a32.78,32.78,0,0,0-4.17.22v2.59l3.16.31q5.74.57,7.93,2.74a8.08,8.08,0,0,1,2.2,6,12.8,12.8,0,0,1-.75,4.67A8,8,0,0,1,45.83,91a6.66,6.66,0,0,1-2.92,1.51,16.71,16.71,0,0,1-3.31.64c-1,.07-2.21.11-3.79.11a46.29,46.29,0,0,1-10.57-1.14Zm33.59.48H49.58l7.1-27.41H70.23l7.1,27.41H68.08l-1-4.34H59.84l-1,4.34Zm4.38-19-1.79,7.68h4L63.7,73.64Zm34.43-1.4H91.28V92.63H82.51V72.24H76.15v-7H97.64v7Z"
                />
            </svg>
        </SvgIcon>
    );
}
