import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Alert, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import { Button, Stack } from "@mui/material";
import { Help } from "../Help";
import IosShareIcon from "@mui/icons-material/IosShare";

type ListSource = "ShoppingList";

const HelpShoppingList = (
    <Help
        title="HOW IT WORKS"
        messages={["This list shows items in your shopping list."]}
        iconMessages={[
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                }}>
                <DeleteIcon style={{ marginRight: "10px" }} />
                <Typography variant="body2">
                    Delete the item from Shopping List.
                </Typography>
            </div>,
        ]}></Help>
);

function getListItem(
    doc: string,
    listSource: ListSource,
    onDelete: (text: string) => void
) {
    return (
        <ListItem
            key={doc}
            secondaryAction={
                <Tooltip title="Delete">
                    <IconButton onClick={() => onDelete(doc)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            }>
            <ListItemText>
                <Typography variant="body1">{doc}</Typography>
            </ListItemText>
        </ListItem>
    );
}

export interface IngredientsListProps {
    listSource: ListSource;
    items: string[];
    onRemove: (text: string) => void;
    onAdd: (text: string[]) => void;
    onClear: () => void;
}

export default function IngredientsList(props: IngredientsListProps) {
    const { listSource, onClear } = props;
    const [filter, setFilter] = React.useState("");

    const items = props.items;
    const filteredItems = React.useMemo(
        () =>
            items.filter((i) => i.toLowerCase().includes(filter.toLowerCase())),
        [filter, items]
    );
    const onAdd = React.useCallback(() => {
        props.onAdd([filter]);
        setFilter("");
    }, [filter, props]);

    const onShare = React.useCallback(() => {
        const title = `Items in ${listSource}`;
        const shareData: ShareData = {
            title,
            text: [title].concat(filteredItems.sort()).join("\n"),
        };
        navigator.share(shareData);
    }, [filteredItems, listSource]);

    return (
        <Stack direction="column" spacing={"10px"}>
            <TextField
                size="small"
                fullWidth={true}
                value={filter}
                label={`Add or Search Item in ${listSource}`}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}
            />

            <Stack direction="row" spacing={"10px"}>
                <Button
                    variant="contained"
                    disabled={!filter || filteredItems.length > 0}
                    onClick={onAdd}>
                    Add item
                </Button>

                <Button
                    variant="contained"
                    disabled={filteredItems.length === 0}
                    onClick={onClear}>
                    Clear Shopping List
                </Button>
                {listSource === "ShoppingList" && HelpShoppingList}
                {(navigator as any).share && filteredItems.length > 0 && (
                    <Tooltip title="Share">
                        <Button
                            onClick={onShare}
                            variant="outlined"
                            endIcon={<IosShareIcon />}>
                            Share
                        </Button>
                    </Tooltip>
                )}
            </Stack>
            {filteredItems.length === 0 && (
                <Alert style={{ marginTop: "10px" }} severity="info">
                    Please search and add items to the list.
                </Alert>
            )}
            <List dense={true}>
                {filteredItems.map((ingredient: string) =>
                    getListItem(ingredient, listSource, props.onRemove)
                )}
            </List>
        </Stack>
    );
}
