import React from "react";
import { AppProps } from "../../interfaces";
import { GlobalMeals } from "../../globalMeals";
import { Chip, LinearProgress, Stack, Container } from "@mui/material";

export interface CuisineSelectorProps {
    appProps: AppProps;
    suggestedCount: number;
    initialSelected: string[];
    onCuisinesSelected: (cuisines: string[]) => void;
}

export function CuisineSelector(props: CuisineSelectorProps) {
    const {
        suggestedCount,
        initialSelected,
        appProps: { meals },
        onCuisinesSelected,
    } = props;

    const [selectedCuisines, setSelectedCuisines] = React.useState<Set<string>>(
        new Set<string>(initialSelected)
    );

    const [availableCuisines] = React.useState(
        Array.from(
            new Set<string>(
                Object.keys(GlobalMeals)
                    .map((key) => GlobalMeals[key].cuisine)
                    .concat(meals.map((meal) => meal.cuisine))
            )
        )
    );

    React.useEffect(() => {
        onCuisinesSelected(Array.from(selectedCuisines));
    }, [onCuisinesSelected, selectedCuisines]);

    const progress = React.useMemo(
        () =>
            selectedCuisines.size >= suggestedCount
                ? 100
                : (selectedCuisines.size / suggestedCount) * 100,
        [selectedCuisines.size, suggestedCount]
    );

    const handleCuisineClick = React.useCallback(
        (cuisine: string) => {
            const updatedSelectedCuisines = new Set(selectedCuisines);
            if (updatedSelectedCuisines.has(cuisine)) {
                updatedSelectedCuisines.delete(cuisine);
            } else {
                updatedSelectedCuisines.add(cuisine);
            }
            setSelectedCuisines(updatedSelectedCuisines);
        },
        [selectedCuisines]
    );

    return (
        <Stack direction="column">
            <LinearProgress variant="determinate" value={progress} />
            <Container>
                {availableCuisines.map((cuisine: string) => (
                    <Chip
                        key={cuisine}
                        style={{ margin: "5px" }}
                        label={cuisine}
                        onClick={() => handleCuisineClick(cuisine)}
                        variant="filled"
                        color={
                            selectedCuisines.has(cuisine)
                                ? "success"
                                : "default"
                        }
                    />
                ))}
            </Container>
        </Stack>
    );
}
